/**
 * 通过vid和token请求后端接口获取视频信息
 */
const jsonp = require('jsonp')

import { PlayerOptions } from '../core/Player'
import getSupportFormats from './getSupportFormats'

const domainSuffix = 'baijiayun.com'
const protocolPrefix = location.protocol + '//'

let apiUrlPrefix = 'www.'

const getVideoInfoPath = '/vod/video/getPlayUrl'

export function getApiUrl(options: PlayerOptions) {
    if (options.privateDomainPrefix) {
        const privateDomainPrefix = options.privateDomainPrefix

        if (options.env === 'test') {
            return protocolPrefix + privateDomainPrefix + '.test-at.' + domainSuffix
        }
        if (options.env === 'beta') {
            return protocolPrefix + privateDomainPrefix + '.beta-at.' + domainSuffix
        }
        return protocolPrefix + privateDomainPrefix + '.at.' + domainSuffix
    } else {
        if (options.env === 'test') {
            apiUrlPrefix = 'test-www.'
        } else if (options.env === 'beta') {
            apiUrlPrefix = 'beta-www.'
        }
        return protocolPrefix + apiUrlPrefix + domainSuffix
    }
}

export default function (options: PlayerOptions) {

    let videoInfoJsonp = getApiUrl(options) + getVideoInfoPath

    let reqParam = [
        'vid=' + options.vid,
        'sid=' + '',
        'render=jsonp',
        'client_type=h5',
        'ver=2',
    ]
    if (options.token == 'wannengtoken') {
        reqParam.push('skip_verify=1')
    } else {
        reqParam.push('token=' + options.token)
    }
    if (options.pid) {
        reqParam.push('pid=' + options.pid)
    }
    if (options.access_key) {
        reqParam.push('access_key=' + options.access_key)
    }

    reqParam.push('supports_format=' + getSupportFormats().join(','))

    let promise = new Promise((_resolve, _reject) => {
        jsonp(videoInfoJsonp + '?' + reqParam.join('&'), function (err: any, res: any) {
            if (res?.code == 0) {
                _resolve(res)
            } else {
                _reject(res)
            }
        })
    })

    return promise
}