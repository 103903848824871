import BPlayer from "..";

const LEVEL: any = {
    info: 4,
    succ: 3,
    wran: 2,
    error: 1,
}

export default class Logger {

    private player: BPlayer

    public level: number = LEVEL.info

    public prefixCommonStyle: string = 'padding: 3px; border-radius: 4px 0 0 4px;  color: #fff'

    public contentStyle: string = 'background: transparent'

    constructor(player: BPlayer) {
        this.player = player
    }

    success(content: string, params?: any) {
        let prefix = 'bplayer succ'
        let bgcolor = 'background: #06d6a0;'

        this.log(`%c ${prefix} %c ${content}`, bgcolor + this.prefixCommonStyle, LEVEL.succ, params)
    }

    info(content: string) {
        let prefix = 'bplayer info'
        let bgcolor = 'background: #577590;'

        this.log(`%c ${prefix} %c ${content}`, bgcolor + this.prefixCommonStyle, LEVEL.info)
    }

    warn(content: string) {
        let prefix = 'bplayer warn'
        let bgcolor = 'background: #f8961e;'

        this.log(`%c ${prefix} %c ${content}`, bgcolor + this.prefixCommonStyle, LEVEL.warn)
    }

    error(content: string) {
        let prefix = 'bplayer error'
        let bgcolor = 'background: #f94144;'
        
        this.log(`%c ${prefix} %c ${content}`, bgcolor + this.prefixCommonStyle, LEVEL.error)
    }

    log(content: string, prefixStyle: string, level: number, params?: any) {
        if (this.level >= level) {
            console.log(content, prefixStyle, this.contentStyle)
            params && console.dir(params)
        }
    }

    setLevel(level: number) {
        this.level = +level || LEVEL.info
        this.player.logLevel = level
    }
}