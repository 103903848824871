/**
 * 代理原生video标签的事件监听，并使其支持链式监听
 */

export default class VideoProxy {
    
    public video: HTMLVideoElement

    constructor(video: HTMLVideoElement) {
        this.video = video
    }

    on (event: string, callback: Function) {
        this.video.addEventListener(event, () => {
            callback()
        })
        return this
    }
}