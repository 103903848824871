import formatOptions from '../function/formatOptions'
import VideoInfo from '../types/videoInfo'
import Template, { showError } from './Template'
import Controller from './Controller'
import Icons from '../function/icons'
import Utils from '../function/utils'
import Hotkey from './Hotkey'
import { Marquee, blinkLamp, rollLamp } from '../plugins/Marquee'
import WaterMark from '../plugins/WaterMark'
import checkUseFFPlayer from '../function/checkUseFFPlayer'
import icons from '../function/icons'
import Events, { eventTypes } from './Events'
import Subtitle from '../plugins/Subtitle'

import Reporter from './Reporter'
import Storage, { StorageData } from './Storage'
import Debugger from '../plugins/Debugger'
import os from 'bjy-common/lib/util/os'
import {
    exitFullscreen,
    requestFullscreen,
    isFullScreen,
} from '../function/fullscreen'
import browser from 'bjy-common/lib/util/browser'
import Logger from './Logger'
import Advertisement from './Advertisement'
import { ERROR_CDEO } from '../types/constant'

export interface PlayerOptions {
    // 是否允许拖拽进度条
    enableDrag: boolean
    // 挂载Dom
    container: HTMLElement
    // 主题色
    theme: string
    // logo {url, link}
    logo: any
    // 封面
    poster: string
    // 背景图
    background: string
    // title 视频标题
    title: string
    // video 对象
    video: VideoInfo
    // Apple airplay
    airplay: boolean
    // 预加载
    preload: string
    // 自动播放
    autoplay: boolean
    // 记忆播放
    memory: boolean
    // 倍速
    rateList: Array<number>
    // 热键
    hotkey: boolean
    // 音量
    volume: 0.75
    // 字幕
    subtitle: any
    // 插件
    plugins: any
    // user信息
    user: any
    // vid 视频的id, 没指定vid就会使用video.url作为播放源
    vid: string
    // token 签名, 用于获取vid对应的视频信息
    token: string
    // 个性域名
    privateDomainPrefix: string
    // env 环境
    env: string
    access_key: string
    pid: string
    // 默认清晰度
    quality: string
    // 数据上报
    reporter: {
        interval: number
        // 自定义参数
        custom: {}
    }
    // 通过vid请求拿到的视频信息
    videoInfo: any
    // 是否是H5
    isH5?: boolean
    // 片头
    startVideo: string
    // 片尾
    endVideo: string
    // 是否有广告
    hasAd: boolean
}

export default class Player {
    static eventsMap: typeof eventTypes = eventTypes

    public isH5: boolean

    public options: PlayerOptions

    public container: HTMLElement

    public template: Template

    public events: Events

    public controller: Controller

    public video: any

    public paused: boolean = true

    public duration: number

    public volume: number

    public lastValidVolume: number

    public theme: string

    public currentTime: number = 0

    public loop: number = 0

    public protect: number = 0

    public barrage: number = 0

    public speed: number = 1

    public quality: string = ''

    public qualityList: Array<object>

    public useFFPlayer: boolean

    public isSeeking: boolean

    public hotkey: Hotkey

    public marquee: Marquee

    public waterMark: WaterMark

    public reporter: Reporter

    public subtitle: Subtitle

    public debugger: Debugger

    public storage: Storage

    public logger: Logger

    public ads: Advertisement

    public hideInfoTimer: any

    public isFullScreen: boolean = false

    public mermoryTime: number = 0

    // 是否正在切换视频源
    public switching: boolean = false

    public ended: boolean = false

    public os: any = os

    public browser: any = browser

    public logLevel: number

    public adPlaying: boolean

    constructor(options: PlayerOptions) {
        if (options.memory) {
            this.storage = new Storage(options)
        }

        this.events = new Events(this)

        this.logger = new Logger(this)

        formatOptions(options, this.storage)
            .then((res) => {
                this.options = res

                if (!this.options.memory) {
                    this.storage = undefined
                }

                this.isH5 = this.options.isH5 =
                    os.android || os.ipad || os.ios || os.itouch

                this.quality = this.options.quality
                
                this.qualityList = this.options.video.quality

                this.useFFPlayer = checkUseFFPlayer(this.options)

                this.container = this.options.container

                this.template = new Template(this)

                this.video = this.template.video

                this.controller = new Controller(this)

                this.debugger = new Debugger(this)

                this.hotkey = new Hotkey(this)

                // IOS微信浏览器播放之后才能触发loadedmetadata，这里先用options里面的duration和缓存处理一下记忆播放进度条
                if (this.browser.wechat && this.os.ios) {
                    this.template.controlBar.querySelector(
                        '.total-time'
                    ).innerHTML = Utils.secondToTime(
                        this.options.videoInfo.duration
                    )

                    this.storage &&
                        this.template.updateProgressBar(
                            null,
                            this.storage.all().time
                        )
                }

                // 百家云点播接口才有上报
                if (this.options.vid && this.options.token) {
                    this.reporter = new Reporter(this)
                }
                if (this.options.plugins.subtitle?.url) {
                    this.subtitle = new Subtitle(this)
                }
                if (this.options.plugins.marquee?.type) {
                    if (this.options.plugins.marquee?.displayMode == 'roll') {
                        this.marquee = new rollLamp(this)
                    } else if (
                        this.options.plugins.marquee?.displayMode == 'blink'
                    ) {
                        this.marquee = new blinkLamp(this)
                    }
                }
                if (this.options.plugins.waterMark?.url) {
                    this.waterMark = new WaterMark(this)
                }
                if (this.options.startVideo || this.options.endVideo) {
                    this.initStartEndVideo()
                }

                if (this.options.hasAd) {
                    this.ads = new Advertisement(this)
                }

                this.logger.success('format options success', this.options)

                this.resize()
            })
            .catch((err: any) => {
                if (err.code === ERROR_CDEO.unsupported) {
                    showError('不支持的视频格式', options.container)
                }
                if (err.code && err.msg) {
                    showError(err.msg, options.container)
                }
            })
    }

    // 片头片尾
    private initStartEndVideo() {
        const wrap = this.template.startEndVideoWrap
        if (wrap) {
            const startVideo: HTMLVideoElement = wrap.querySelector(
                '.bplayer-start-video'
            )
            const endVideo: HTMLVideoElement =
                wrap.querySelector('.bplayer-end-video')
            const stat = wrap.querySelector('.bplayer-video-stat')

            if (startVideo) {
                stat.addEventListener('click', () => {
                    if (stat.innerHTML === Icons.play) {
                        startVideo.play()
                    }
                })
                startVideo.addEventListener('playing', () => {
                    stat.innerHTML = ''
                })
                startVideo.addEventListener('ended', () => {
                    this.options.startVideo = ''
                    startVideo.src = ''
                    startVideo.remove()
                    wrap.style.display = 'none'
                    this.play()
                })
            } else {
                wrap.style.display = 'none'
            }

            if (endVideo) {
                this.on('ended', () => {
                    if (this.options.endVideo) {
                        wrap.style.display = 'block'
                        endVideo.style.display = 'block'
                        endVideo.play()
                    }
                })

                endVideo.addEventListener('playing', () => {
                    stat.innerHTML = ''
                })

                endVideo.addEventListener('ended', () => {
                    this.options.endVideo = ''
                    endVideo.src = ''
                    endVideo.remove()
                    this.trigger(EVENTS.ENDED_AD)
                    wrap.remove()
                    // 片尾结束后再重播
                    if (this.loop && !this.switching) {
                        this.replay()
                    }
                })
            }
        }
    }

    initPlayer() {
        const data: StorageData = this.storage?.all() || {}
        // 初始化字幕判断
        if (
            data.subtitle === undefined &&
            this.options?.plugins?.subtitle?.url
        ) {
            data.subtitle = 1
        }
        this.duration = this.video.duration
        this.mermoryTime = data.time
        this.template.updateProgressBar(null, data.time)
        if (this.switching) {
            // 加载完metadata后更新一下清晰度UI
            this.template.updateQualityUI()
            this.switching = false
            !this.useFFPlayer && this.play()
        } else {
            this.initSetting(data)
            this.setVolume(data.volume || this.options.volume, true, false)
            this.toggleBarrage(data.barrage || this.barrage)
            this.speed = data.speed || 1
            this.template.updateSpeedUI()
        }
    }

    initSetting(data: StorageData) {
        if (data.subtitle && this.subtitle) {
            this.toggleSubtitle(data.subtitle)
            let control = this.template.settingsControlWrap.querySelector(
                ".bplayer-option-item[data-name='subtitle']"
            )
            control.setAttribute('data-value', '' + data.subtitle)
        }
        if (data.protect) {
            this.setProtect(data.protect)
            let control = this.template.settingsControlWrap.querySelector(
                ".bplayer-option-item[data-name='protect']"
            )
            control.setAttribute('data-value', '' + data.protect)
        }
        if (data.loop) {
            this.setLoop(data.loop)
            let control = this.template.settingsControlWrap.querySelector(
                ".bplayer-option-item[data-name='loop']"
            )
            control.setAttribute('data-value', '' + data.loop)
        }
    }

    setCurrentTime(timeStamp: number) {
        this.currentTime = timeStamp
        this.storage?.set({
            time: timeStamp,
        })
    }

    toggle() {
        if (this.video.paused) {
            this.play()
        } else {
            this.pause()
        }
    }

    toggleSeekingStat(isSeeking: boolean) {
        this.isSeeking = isSeeking
        this.template.playerStatIcon.innerHTML = isSeeking
            ? icons.loading
            : icons.play
        if (isSeeking) {
            this.template.container.classList.add('bplayer-seeking')
        } else {
            this.template.container.classList.remove('bplayer-seeking')
        }
    }

    replay() {
        this.ended = false
        this.video.currentTime = 0
        this.template.updateProgressBar()
        if (this.useFFPlayer) {
            this.video.replay()
            this.template.playBtn.innerHTML = Icons.stop
            this.template.container.classList.add('bplayer-playing')
        } else {
            this.seek(0)
        }
    }

    play() {
        if (this.adPlaying) {
            return
        }

        // 重播
        if (this.ended) {
            this.replay()
            return
        }

        try {
            // H5需要首次play之后才能seek
            if (this.video.paused) {
                this.video.play()
                if (this.useFFPlayer) {
                    this.video.resume()
                }
                this.template.playBtn.innerHTML = Icons.stop
                this.template.container.classList.add('bplayer-playing')
                this.paused = false
                this.showActionTip('正在播放')
            }
        } catch (error) {
            this.pause()
        }
    }

    pause() {
        this.paused = true
        this.showActionTip('已暂停')
        this.video.pause()
        this.template.playBtn.innerHTML = Icons.play
        this.template.container.classList.remove('bplayer-playing')
    }

    seek(time: number, asyncTemplate = true, autoplay = true) {
        time = Math.max(time, 0)
        time = Math.min(time, this.video.duration)

        this.video.currentTime = time
        this.currentTime = time

        if (this.useFFPlayer) {
            this.video.seek(time)
        }

        if (asyncTemplate) {
            this.template.updateProgressBar()
        }

        if (autoplay && !this.isH5 && !this.useFFPlayer && !this.paused) {
            this.play()
        }
    }

    setSpeed(speed: number) {
        speed = Math.min(speed, 2)
        speed = Math.max(speed, 0.5)
        if (isNaN(speed)) {
            speed = 1
        }
        if (this.video.speed !== speed) {
            this.video.playbackRate = speed
            this.speed = speed
            this.template.updateSpeedUI()
            this.storage?.set({
                speed: speed,
            })
            this.events.trigger(eventTypes.RATE_CHANGE, speed)
        }
    }

    /**
     *
     * @param volume 0-1
     * @param asyncTemplate 是否需要同步更新UI
     */
    setVolume(volume: number, asyncTemplate = true, tip = true) {
        volume = Math.min(volume, 1)
        volume = Math.max(volume, 0)
        this.video.volume = volume
        this.volume = volume

        if (asyncTemplate) {
            this.template.updateVolumeBar()
        }
        tip && this.showActionTip('音量: ' + (volume * 100).toFixed(0))
        this.events.trigger(eventTypes.VOLUME_CHANGE, volume)
        this.storage?.set({
            volume: volume,
        })
        if (volume > 0) {
            this.lastValidVolume = volume
        }
    }

    switchQuality(qualityInfo: any) {
        const quality = qualityInfo.quality
        let label = qualityInfo.label

        const playInfo = this.options.videoInfo?.play_info
        if (playInfo && playInfo[quality]) {
            if (!label) {
                label = playInfo[quality].definition
            }
            const url = playInfo[quality].cdn_list[0].url
            if (url && url !== this.options.video.url) {
                this.quality = quality
                this.switching = true
                this.storage?.set({
                    quality: quality,
                })
                this.options.video.url = url
                this.options.video.cdn_info = playInfo[quality].cdn_list[0]
                this.showActionTip('正在切换至: ' + label)
                this.trigger(eventTypes.QUALITY_CHANGE, label)
                this.mermoryTime = this.video.currentTime
                this.changeVideo(url)
            }
        }
    }

    // 字幕开关
    toggleSubtitle(val: number) {
        !!val ? this.subtitle.show() : this.subtitle.hide()
        this.storage?.set({
            subtitle: +!!val,
        })
    }
    // 护眼模式开关
    setProtect(val: number) {
        this.protect = val
        if (val) {
            this.template.protectionMask.style.width = '100%'
        } else {
            this.template.protectionMask.style.width = '0'
        }
        !!val ? this.showActionTip('护眼: 开') : this.showActionTip('护眼: 关')
        this.storage?.set({
            protect: +!!val,
        })
    }
    // 循环播放开关
    setLoop(val: number) {
        this.loop = val
        !!val ? this.showActionTip('循环: 开') : this.showActionTip('循环: 关')
        this.storage?.set({
            loop: +!!val,
        })
    }

    // 开关静音
    toggleMute() {
        if (this.video.volume === 0) {
            this.setVolume(this.lastValidVolume)
        } else {
            this.setVolume(0)
        }
    }

    // 开关弹幕
    toggleBarrage(val?: number) {
        if (!this.options.plugins?.barrage) {
            return
        }
        if (val !== undefined) {
            this.barrage = val
        } else {
            this.barrage = +!this.barrage
        }
        if (this.barrage) {
            this.template.barrageControlBtn.innerHTML = icons.barrageOn
            this.template.barrageControlBtn.setAttribute(
                'bplayer-tip',
                '弹幕开'
            )
        } else {
            this.template.barrageControlBtn.innerHTML = icons.barrageOff
            this.template.barrageControlBtn.setAttribute(
                'bplayer-tip',
                '弹幕关'
            )
        }
        this.storage?.set({
            barrage: this.barrage,
        })
    }

    // events
    on(name: string, callback: Function) {
        return this.events.on(name, callback)
    }

    trigger(name: string, data?: any) {
        return this.events.trigger(name, data)
    }

    off(name: string) {
        return this.events.off(name)
    }

    // 左下角提示
    showActionTip(content: string, duration?: number) {
        if (!content.trim()) {
            return
        }

        const tipEl = this.template.actionTip
        tipEl.innerHTML = content

        if (tipEl.classList.contains('hide')) {
            tipEl.classList.remove('hide')
        }

        this.hideInfoTimer && clearTimeout(this.hideInfoTimer)

        this.hideInfoTimer = setTimeout(() => {
            tipEl.classList.add('hide')
        }, duration || 1000)
    }

    async setFullScreen() {
        if (!this.isFullScreen) {
            await requestFullscreen(this)
        } else {
            await exitFullscreen(this)
        }
        this.isFullScreen = isFullScreen(this)

        this.trigger(eventTypes.FULLSCREEN_CHANGE, this.isFullScreen)
        if (this.isFullScreen) {
            this.template.fullScreenBtn.innerHTML = icons.fullscreenOff
            this.template.fullScreenBtn.setAttribute('bplayer-tip', '退出全屏')
        } else {
            this.template.fullScreenBtn.innerHTML = icons.fullscreenOn
            this.template.fullScreenBtn.setAttribute('bplayer-tip', '进入全屏')
        }
    }

    // toggle debug panel
    toggleDebugPanel() {
        if (this.debugger) {
            this.debugger.toggle()
        }
    }

    // change video source
    changeVideo(url: string) {
        this.pause()
        this.template.playerStatIcon.innerHTML = icons.loading
        if (this.useFFPlayer) {
            this.video.stop().then(() => {
                if (this.options.video.type === 'ev2') {
                    this.video.ev2Desrypt.stop()
                    this.video.ev2Desrypt.run(url).then(() => {
                        this.video.load(
                            { url: url },
                            {
                                from: 0,
                                to: -112,
                            }
                        )
                        this.play()
                    })
                } else {
                    this.video.load(
                        { url: url },
                        {
                            from: 0,
                            to: -1,
                        }
                    )
                    this.play()
                }
            })
        } else {
            this.video.src = url
            this.play()
        }
    }

    resize() {
        this.useFFPlayer &&
            this.video.resize(
                this.container.offsetWidth,
                this.container.offsetHeight
            )
    }

    destroy() {
        if (this.useFFPlayer) {
            this.video && this.video.destroy()
            this.isH5 && FFPlayer.disableKeepScreenOn()
        } else {
            this.video && this.video.remove()
        }
    }
}

export const EVENTS = eventTypes
