import { PlayerOptions } from '../core/Player'

// 需要用到ffplayer的格式
const FFPLAYER_TYPES = ['ev2', 'ev1', 'flv']

export default function (options: PlayerOptions) {
    const videoType = options.video.type

    return FFPlayer && FFPlayer.isSupported() && FFPLAYER_TYPES.indexOf(videoType) !== -1
}
