import { PlayerOptions } from '../core/Player'
import os from 'bjy-common/lib/util/os'

// const useWebCodec = browser.chrome && (+browser.version.split('.')[0] >= 94)
const useWebCodec = false

export function useWorker() {
    let enableWorker = true
    if (os.ios && (+os.version.split('.')[0] < 14)) {
        enableWorker = false
    }
    return enableWorker
}

export default function (canvas: HTMLCanvasElement, options: PlayerOptions) {
    const ev2Desrypt = new FFPlayer.Plugins.Ev2Desrypt()
    const fetchLoader = new FFPlayer.FetchLoader()
    const fileLoader = new FFPlayer.FileLoader()
    const hlsLoader = new FFPlayer.HLSLoader()
    const fetchWorkerLoader = new FFPlayer.IOWorkerLoader(fetchLoader.type)
    const useH265 = false
    const isLive = false

    const videoInfo = options.video

    let player: any

    function create() {
        player = new FFPlayer.Player(canvas, {
            enablePthreads: false,
            enableWorker: useWorker(),
            useCombine: true,
            useWebCodec: useWebCodec,
            enableHardwareAcceleration: true,
            enableMeter: true,

            combineUrl: useH265
                ? 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/combine/combine-flv-hevc-aac.wasm'
                : 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/combine/combine-flv-h264-aac.wasm',
            // combineUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/combine/combine-flv-h264-aac-worker.wasm',

            contextUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/context/context.wasm',
            streamBufferUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/buffer/buffer.wasm',

            demuxerUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/demux/demux-flv.wasm',
            audioDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-aac.wasm',
            videoDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-h264.wasm',

            // contextUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/context/context-worker.wasm',
            // streamBufferUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/buffer/buffer-worker.wasm',

            // demuxerUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/demux/demux-flv-worker.wasm',
            // audioDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-aac-worker.wasm',
            // videoDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-h264-worker.wasm',
            // loader: fileLoader,
            loader: fetchLoader,
            // loader: hlsLoader,
            // loader: fetchWorkerLoader,

            // demuxerUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/demux/demux-mpegts.wasm',
            // audioDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-aac.wasm',
            // videoDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-h264.wasm',
            // loader: hlsLoader,

            // demuxerUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/demux/demux-mov.wasm',
            // audioDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-aac.wasm',
            // videoDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-h264.wasm',
            // loader: fileLoader,

            // demuxerUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/demux/demux-avi.wasm',
            // audioDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-mp3.wasm',
            // videoDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-mpeg4.wasm',
            // loader: fileLoader,

            // demuxerUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/demux/demux-mpegts.wasm',
            // audioDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-aac.wasm',
            // videoDecoderUrl: 'https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/decode/decode-hevc.wasm',
            // loader: fileLoader,

            frameQueueSize: 10,
            bufferSize: 8 * 1024 * 1024,
            bufferWin: 100 * 1024,
            isLive: isLive,
            extMap: {
                ev1: 'flv',
                ev2: 'flv',
            },
            channelsDescription: 16,
            preserveDrawingBuffer: true
        })

        player.ioLoader.bufferPipe = (
            chunk: any,
            bytesStart: any,
            receivedLength: any
        ) => {
            if (player.ioLoader.getStreamExt() === 'ev1') {
                if (bytesStart < 100) {
                    const buffer = new Uint8Array(chunk)
                    for (let i = bytesStart; i < 100; i++) {
                        buffer[i] = ~buffer[i]
                    }
                }
            } else if (player.ioLoader.getStreamExt() === 'ev2') {
                return ev2Desrypt.desrypt(chunk, bytesStart)
            }
            return chunk
        }

        player.ev2Desrypt = ev2Desrypt

        const promise = []
        if (videoInfo.type === 'ev2' && !ev2Desrypt.isReady()) {
            promise.push(
                ev2Desrypt.ready('https://live-cdn.baijiayun.com/bplayer/1.3.1/dep/plugins/ev2-desrypt.wasm')
            )
        }
        promise.push(player.ready())
        Promise.all(promise).then(() => {
            if (videoInfo.type === 'ev2') {
                ev2Desrypt.stop()
                ev2Desrypt.run(videoInfo.url).then(() => {
                    loadMedia()
                })
            } else {
                loadMedia()
            }
        })
        
        player.setLogLevel(2)

        player.speed = 1.0

        // proxy paused
        Object.defineProperty(player, 'paused', {
            configurable: true,
            get: function () {
                return !this.isPlaying()
            },
        })

        // proxy playbackRate
        Object.defineProperty(player, 'playbackRate', {
            configurable: true,
            get: function () {
                return this.speed
            },
            set: function (speed) {
                this.speed = speed
                this.setPlaybackRate(speed)
            },
        })

        // proxy volume
        Object.defineProperty(player, 'volume', {
            configurable: true,
            get: function () {
                return this.getVolume()
            },
            set: function (volume) {
                this.setVolume(volume)
            },
        })
    }

    function loadMedia() {
        player.load(
            {
                url: videoInfo.url,
            },
            {
                from: 0,
                to: videoInfo.type === 'ev2' ? -112 : -1,
            }
        )
    }

    if (player) {
        player.stop().then(() => {
            if (videoInfo.type === 'ev2') {
                ev2Desrypt.stop()
                ev2Desrypt.run(videoInfo.url).then(() => {
                    loadMedia()
                })
            } else {
                loadMedia()
            }
        })
    } else {
        create()
    }
    
    player.reload = loadMedia

    return player
}
