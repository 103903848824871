let updateSkinStyle: any = null

export default function (themeColor: string) {
    themeColor = themeColor || '#1795ff'
    if (updateSkinStyle) {
        document.head.removeChild(updateSkinStyle)
    }
    updateSkinStyle = document.createElement('style')
    updateSkinStyle.appendChild(document.createTextNode(''))
    document.head.appendChild(updateSkinStyle)

    var rules =
        `:root {--brand-primary: ${themeColor}}`
    updateSkinStyle.sheet.insertRule(rules, 0)
}