define(['@babel/runtime-corejs3/core-js-stable/instance/slice'], function (_slice) {
    'use strict';
    var _interopRequireDefault = require('@babel/runtime-corejs3/helpers/interopRequireDefault');
    _slice = _interopRequireDefault(_slice);
    define(function (require, exports) {
        var imageList = [];
        var objToStr = function objToStr(obj) {
            var str = '';
            for (var key in obj) {
                str += key + '=' + obj[key] + '&';
            }
            str = (0, _slice.default)(str).call(str, 0, -1);
            return str;
        };
        var send = function send(url, data) {
            var params = data || {};
            var target = (url || liveUrl) + '?' + objToStr(params);
            var img = new Image();
            var index = imageList.push(img) - 1;
            var complete = function complete() {
                img = img.onload = img.onerror = null;
                delete imageList[index];
            };
            img.onload = complete;
            img.onerror = function () {
                complete();
                setTimeout(function () {
                    send(url, data);
                }, 1000);
            };
            img.src = target;
        };
        return send;
    });
});