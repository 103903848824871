<div class="bplayer-ad-wrap">
    <img src="{{ bgsrc }}">
    <div class="bplayer-ad-action">
        {{ if showTimer }}
        <div class="bplayer-ad-countdown">{{ duration }}秒</div>
        {{ /if }}
        {{ if skip }}
        <div class="bplayer-ad-skip">跳过广告</div>
        {{ /if }}
    </div>
    
    {{ if link }}
    <a href="{{ link }}" target="_blank" class="bplayer-ad-detail">了解详情</a>
    {{ /if }}
</div>