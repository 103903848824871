import BPlayer from '..'

function pixelFixed(pixel: string|number): string {
    return ('' + pixel).replace('px', '') + 'px'
}

interface WaterMarkOptions {
    // 水印图片地址
    url: string,
    // 宽
    width: string,
    // 高
    height: string,
    // 透明度
    opacity: string,
    // 位置 top-right || top-left || bottom-left || bottom-right
    position: string
}

export default class WaterMark {
    private player: BPlayer
    private watermarkElement: HTMLImageElement = null
    private options: WaterMarkOptions = null

    constructor(player: BPlayer) {
        this.player = player
        this.create()
    }

    create() {
        const options = this.player.options.plugins.waterMark
        this.watermarkElement && this.destroy()
        this.watermarkElement = document.createElement('img')
        this.player.template.customWrap.appendChild(this.watermarkElement)
        this.style(options)
    }

    style(options: WaterMarkOptions) {
        if (options?.width) {
            options.width = pixelFixed(options.width)
        }
        if (options?.height) {
            options.height = pixelFixed(options.height)
        }
        options = Object.assign(this.defaultOption(), options)

        this.options = options

        this.watermarkElement.src = options.url
        this.watermarkElement.style.height = options.height
        this.watermarkElement.style.width = options.width
        this.watermarkElement.style.opacity = options.opacity
        this.watermarkElement.className = 'bplayer-watermark-wrap position-' + options.position
    }

    destroy() {
        if(this.watermarkElement) {
            this.player.template.customWrap.removeChild(this.watermarkElement)
            this.watermarkElement = null
        }
    }

    defaultOption() {
        return this.options || {
            width: '120px',
            height: '32px',
            url: 'https://live-cdn.baijiayun.com/homepage/asset/frontend/common/img/logo-blue_3dd169c315.png',
            opacity: .6,
            position: 'top-right'
        }
    }
}