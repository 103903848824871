import { PlayerOptions } from "./Player"
import MD5 from 'bjy-common/lib/helper/MD5'
export interface StorageData {
    // unique 唯一识别码, 百家云接口通过 bjy_vid_[vid], 其他使用url
    unique: string,
    // 音量
    volume?: number,
    // 播放位置
    time?: number,
    // 倍速
    speed?: number,
    // 清晰度
    quality?: string,
    // 循环
    loop?: number,
    // 护眼模式
    protect?: number,
    // 字幕
    subtitle?: number,
    // 跑马灯
    marquee?: number,
    // 弹幕
    barrage?: number
}

export default class Storage {

    private readonly field: string = 'bplayer'

    private unique: string

    public options: PlayerOptions

    private readonly md5: any = new MD5()

    constructor(options: PlayerOptions) {
        this.options = options
        // 如果是百家云点播，根据vid生成unique
        if (this.options.vid && this.options.token) {
            this.unique = 'bjy_vid_' + this.options.vid
        } else {
            this.unique = this.md5.string(this.options.video.url)
        }

        const data = JSON.parse(window.localStorage.getItem(this.field)) || {}
        if (!data[this.unique]) {
            this.set({})
        }
    }

    getDefaultOptions() {
        return {
            unique: this.unique,
            volume: this.options.volume || 0.75, 
            time: 0, 
            speed: 1,
            loop: 0,
            subtitle: 1,
            protect: 0,
            quality: '',
        }
    }

    getTarget() {
        const data = JSON.parse(window.localStorage.getItem(this.field)) || {}
        const target = data[this.unique]

        if (typeof target === 'object') {
            return target
        } else {
            return this.getDefaultOptions()
        }
    }

    has(key: string) {
        return Object.keys(this.getTarget()).indexOf(key) !== -1
    }

    set(obj: Object) {
        let target = this.getTarget()
        target = Object.assign(target, obj)
        const data = JSON.parse(window.localStorage.getItem(this.field)) || {}
        data[this.unique] = target
        window.localStorage.setItem(this.field, JSON.stringify(data))
    }

    get(key: string) {
        if(this.has(key)) {
            const target = this.getTarget()
            return target[key]
        } else {
            return ''
        }
    }

    all() {
        return this.getTarget()
    }

    clean() {
        window.localStorage.removeItem(this.field)
    }
}