import BPlayer from "..";
import { formatAdOptions } from "../function/formatOptions";
import { EVENTS } from "./Player";
import { renderAd } from "./Template";


export default class Advertisement {

    public player: BPlayer

    public adOptions: any

    public countDownInterval: any

    public adWrap: Element

    public skipBtn: Element

    public countDown: number

    private hasEndPlayed: boolean

    public startAds: any

    public endAds: any

    constructor(player: BPlayer) {
        this.player = player

        if (this.player.options.videoInfo.ad?.start?.length) {
            this.startAds = this.player.options.videoInfo.ad.start[0]
            this.adOptions = formatAdOptions(this.player.options.videoInfo.ad?.start[0])
            this.countDown = this.adOptions.duration
            this.start()
        }

        if (this.player.options.videoInfo.ad?.end?.length) {
            this.endAds = this.player.options.videoInfo.ad.end[0]
        }

        this.player.on(EVENTS.ENDED_AD, () => {
            if (this.player.options.videoInfo.ad?.end?.length && !this.hasEndPlayed) {
                this.adOptions = formatAdOptions(this.player.options.videoInfo.ad?.end[0])
                this.adOptions.position = 'end'
                this.countDown = this.adOptions.duration
                this.start()
            }
        })
    }

    start() {
        this.adWrap && this.destory()
        this.player.adPlaying = true
        this.adWrap = renderAd(this.adOptions, this.player.template.container)
        this.skipBtn = this.adWrap.querySelector('.bplayer-ad-skip')
        this.skipBtn && this.skipBtn.addEventListener('click', () => {
            this.skip()
        })

        if (this.countDownInterval) {
            clearInterval(this.countDownInterval)
            this.countDownInterval = null
        }
        this.countDownInterval = setInterval(() => {
            this.countDown--
            if (this.countDown === this.adOptions.skipSeconds && this.skipBtn) {
                // @ts-ignore
                this.skipBtn.style.display = 'inline-block'
            }
            this.adWrap.querySelector('.bplayer-ad-countdown').innerHTML = this.countDown + '秒'
            if (this.countDown === 0) {
                this.destory()
                if (this.adOptions.position === 'end' && this.player.loop) {
                    this.player.replay()
                }
            }
        }, 1000)
    }

    skip() {
        if (this.adOptions.position === 'end' && this.player.loop) {
            this.player.replay()
        }
        this.destory()
    }

    private destory() {
        clearInterval(this.countDownInterval)
        this.countDownInterval = null
        this.player.adPlaying = false
        this.adWrap.remove()
        this.adWrap = null
        this.startAds = null
        if (this.adOptions.position === 'end') {
            this.hasEndPlayed = true
            this.endAds = null
        }
    }
}