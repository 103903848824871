import BPlayer from '..'
import Icons from '../function/icons'
import Utils from '../function/utils'
import VideoProxy from '../function/proxyHTMLVideo'
import { eventTypes } from './Events'
import { syncFullscreenStat } from '../function/fullscreen'

export function handleAll(list: NodeList, callback: Function) {
    list.forEach((item) => {
        callback.apply(item)
    })
}

export default class Controller {
    private player: BPlayer
    private isDragingProgressBar: boolean
    private isDragingVolumeBar: boolean
    private hideTimer: any
    private hideDelay: number = 5000

    constructor(player: BPlayer) {
        this.player = player
        this.isDragingProgressBar = false

        this.bindVideo()
        this.bindIconBtn()
        this.bindPlayWrap()
        this.bindProgress()
        this.bindVolumeBar()
        this.bindWindow()
        this.bindControlBar()
        this.initHideControllerTimer()
        this.bindLowEndTip()
    }

    bindLowEndTip() {
        if (this.player.template.lowEndTipBar) {
            this.player.template.lowEndTipBar.querySelector('.bplayer-close-btn').addEventListener('click', () => {
                this.player.template.lowEndTipBar.remove()
            })
        }
    }

    // init video data : video source load complete
    bindVideo() {
        let video = this.player.video
        if (this.player.useFFPlayer) {
            this.player.toggleSeekingStat(true)
            video
                .on(FFPlayer.Events.Player.META_DATA, (data: any) => {
                    this.player.video.duration = Math.floor(data.duration)
                    this.player.initPlayer()
                    this.player.template.controlBar.querySelector(
                        '.total-time'
                    ).innerHTML = Utils.secondToTime(this.player.video.duration)
                    this.player.trigger(eventTypes.LOADEDMETADATA)
                    this.player.toggleSeekingStat(false)
                })
                .on(FFPlayer.Events.Player.TIME, (currentTime: any) => {
                    // 切换视频源直接返回
                    if (this.player.switching) {
                        return
                    }
                    this.player.video.currentTime = currentTime
                    this.player.setCurrentTime(this.player.video.currentTime)
                    !this.isDragingProgressBar && this.player.template.updateProgressBar()
                    this.player.trigger(
                        eventTypes.TIMEUPDATE,
                        this.player.video.currentTime
                    )
                })
                .on(FFPlayer.Events.Player.TIMEOUT, () => {
                    // this.player.showActionTip('视频加载失败', 5000)
                })
                .on(FFPlayer.Events.Player.STATS, (stats: any) => {
                    this.player.template.updateLoadedBar(stats.preloadTime)
                })
                .on(FFPlayer.Events.Player.PAUSED, () => {
                    this.player.pause()
                    this.player.trigger(eventTypes.PAUSE)
                })
                .on(FFPlayer.Events.Player.PLAYING, () => {
                    this.player.isH5 && FFPlayer.enableKeepScreenOn()
                })
                .on(FFPlayer.Events.Player.STOPED, () => {
                    // ffplayer的重播需要放到stoped事件触发之后
                    // 判断没有片尾或片尾广告的时候立即触发重播，有片尾的话放到片尾结束再重播
                    if (!this.player.ads?.endAds && !this.player.options.endVideo && !this.player.switching && this.player.loop) {
                        this.player.replay()
                    } 
                    // 判断背景图设置，视频结束后显示背景图
                    const background = this.player.options.background
                    if (background) {
                        this.player.template.customWrap.style.backgroundImage = `url('${background}')`
                    }
                })
                .on(FFPlayer.Events.Player.START, () => {
                    this.player.template.customWrap.style.backgroundImage = 'none'
                    this.player.isH5 && FFPlayer.enableKeepScreenOn()
                    this.player.trigger(eventTypes.PLAY)
                    // ffplayer 需要在可以播放后再设置一下速率，处理记忆播放速率设置不生效的问题
                    this.player.setSpeed(this.player.speed)
                })
                .on(FFPlayer.Events.Player.FIRST_VIDEO_FRAME_RENDERED, () => {
                    this.player.trigger(eventTypes.CANPLAY)
                    this.player.mermoryTime && this.player.seek(this.player.mermoryTime)
                })
        } else {
            video = new VideoProxy(this.player.video)
            video
                .on(eventTypes.LOADEDMETADATA, () => {
                    this.player.initPlayer()
                    this.player.template.controlBar.querySelector(
                        '.total-time'
                    ).innerHTML = Utils.secondToTime(this.player.video.duration)
                    this.player.trigger(eventTypes.LOADEDMETADATA)
                    this.player.toggleSeekingStat(false)
                })
                .on(eventTypes.TIMEUPDATE, () => {
                    // 切换视频源直接返回
                    if (this.player.switching) {
                        return
                    }
                    this.player.setCurrentTime(this.player.video.currentTime)
                    !this.isDragingProgressBar && this.player.template.updateProgressBar()
                    if (this.player.video.buffered?.length) {
                        const loadedTime = this.player.video.buffered.end(this.player.video.buffered.length-1)
                        this.player.template.updateLoadedBar(loadedTime)
                    }
                    this.player.template.controlBar.querySelector(
                        '.played-time'
                    ).innerHTML = Utils.secondToTime(
                        this.player.video.currentTime
                    )
                    if (!this.player.video.paused && this.player.paused) {
                        this.player.template.playBtn.innerHTML = Icons.stop
                        this.player.template.container.classList.add('bplayer-playing')
                        this.player.paused = false
                    }
                    this.player.trigger(
                        eventTypes.TIMEUPDATE,
                        this.player.video.currentTime
                    )
                })
                .on(eventTypes.CANPLAY, () => {
                    this.player.toggleSeekingStat(false)
                    this.player.trigger(eventTypes.CANPLAY)
                    this.player.setSpeed(this.player.speed)
                    if (this.player.switching) {
                        this.player.play()
                        this.player.switching = false
                    }
                })
                .on(eventTypes.PLAYING, () => {
                    this.player.template.customWrap.style.backgroundImage = 'none'
                    this.player.play()
                    if (this.player.mermoryTime > 0) {
                        this.player.seek(this.player.mermoryTime)
                        this.player.mermoryTime = 0
                    }
                })
                .on(eventTypes.ENDED, () => {
                    // 判断没有片尾或片尾广告的时候立即触发重播，有片尾的话放到片尾结束再重播
                    if (!this.player.ads?.endAds && !this.player.options.endVideo && !this.player.switching && this.player.loop) {
                        this.player.replay()
                    }
                    // 判断背景图设置，视频结束后显示背景图
                    const background = this.player.options.background
                    if (background) {
                        this.player.template.customWrap.style.backgroundImage = `url('${background}')`
                    }
                })
        }

        // common listener
        video
            .on(eventTypes.PLAYING, () => {
                this.player.template.customWrap.style.backgroundImage = 'none'
                this.player.toggleSeekingStat(false)
                this.player.trigger(eventTypes.PLAYING)
            })
            .on(eventTypes.PAUSE, () => {
                this.player.pause()
                this.player.trigger(eventTypes.PAUSE)
            })
            .on(eventTypes.ERROR, () => {
                this.player.logger.error('play error')
            })
            .on(eventTypes.SEEKING, () => {
                this.player.toggleSeekingStat(true)
                this.player.trigger(eventTypes.SEEKING)
            })
            .on(eventTypes.SEEKED, () => {
                this.player.toggleSeekingStat(false)
                this.player.video.paused && this.player.play()
                this.player.trigger(eventTypes.SEEKED)
            })
            .on(eventTypes.ENDED, () => {
                this.player.storage && this.player.storage.set({ time: 0 })

                // ffplayer 会出现播放结束后没有及时更新time，导致进度条看起来没走完
                if (this.player.useFFPlayer) {
                    this.player.video.currentTime = this.player.video.duration
                }
                if (!this.player.loop) {
                    this.player.pause()
                    this.player.ended = true
                }
                this.player.template.updateProgressBar()
                this.player.trigger(eventTypes.ENDED)
                if (!this.player.options.endVideo) {
                    this.player.trigger(eventTypes.ENDED_AD)
                }
                // 判断背景图设置，视频结束后显示背景图
                const background = this.player.options.background
                if (background) {
                    this.player.template.customWrap.style.backgroundImage = `url('${background}')`
                }
            })
    }

    bindWindow() {
        window.addEventListener(this.player.isH5 ? 'touchmove' : 'mousemove', (e: PointerEvent) => {
            this.isDragingProgressBar && this.player.template.updateProgressBar(e)
            this.isDragingVolumeBar && this.player.template.updateVolumeBar(e)
        })

        window.addEventListener(
            this.player.isH5 ? 'touchend' : 'mouseup',
            (e: PointerEvent) => {
                if (this.isDragingProgressBar && this.player.options.enableDrag) {
                    let currentTime =
                        this.player.template.updateProgressBar(e) * this.player.video.duration
                    this.player.seek(currentTime, false)
                }
                if (this.isDragingVolumeBar) {
                    let volume = this.player.template.updateVolumeBar(e)
                    this.player.setVolume(volume, false)
                }
                setTimeout(() => {
                    this.isDragingProgressBar = false
                    this.isDragingVolumeBar = false
                }, 0)
            }
        )

        window.addEventListener('resize', () => {
            this.player.resize()
        })

        window.addEventListener('fullscreenchange', () => {
            syncFullscreenStat(this.player)
        })
    }

    bindIconBtn() {
        this.player.template.playBtn.addEventListener(
            'click',
            (e: PointerEvent) => {
                this.player.toggle()
                e.stopPropagation()
            }
        )

        this.player.template.fullScreenBtn.addEventListener('click', () => {
            this.player.setFullScreen()
        })

        !this.player.isH5 &&
            this.player.template.volumeControlBtn.addEventListener(
                'click',
                () => {
                    this.player.toggleMute()
                }
            )

        this.player.template.barrageControlBtn &&
            this.player.template.barrageControlBtn.addEventListener(
                'click',
                () => {
                    this.player.toggleBarrage()
                }
            )
    }

    bindProgress() {
        this.player.template.progressBar.addEventListener(
            'click',
            (e: PointerEvent) => {
                if (!this.isDragingProgressBar && this.player.options.enableDrag) {
                    let currentTime =
                        this.player.template.updateProgressBar(e) * this.player.video.duration
                    this.player.seek(currentTime, false)
                }
            }
        )

        this.player.template.progressBar.addEventListener(
            this.player.isH5 ? 'touchstart' : 'mousedown',
            (e) => {
                this.isDragingProgressBar = !this.isDragingProgressBar
                e.stopPropagation()
            }
        )

        !this.player.isH5 &&
            this.player.template.progressBar.addEventListener(
                'mousemove',
                (e) => {
                    let time =
                            e.offsetX /
                            this.player.template.progressBar.offsetWidth,
                        percent = time * 100 + '%'
                    this.player.template.showThumbnails.querySelector(
                        '.time'
                    ).innerHTML = Utils.secondToTime(
                        time * this.player.duration
                    )
                    this.player.template.showThumbnails.style.left = percent
                }
            )
    }

    bindVolumeBar() {
        // H5不需要音量调整
        if (this.player.isH5) {
            return
        }
        this.player.template.volumeBar.addEventListener(
            'click',
            (e: PointerEvent) => {
                if (!this.isDragingVolumeBar) {
                    let volume = this.player.template.updateVolumeBar(e)
                    this.player.setVolume(volume, false)
                }
            }
        )

        this.player.template.volumeBar.addEventListener('mousedown', (e) => {
            this.isDragingVolumeBar = !this.isDragingVolumeBar
            e.stopPropagation()
        })
    }

    bindPlayWrap() {
        const controlWarp = this.player.container.querySelector('.bplayer-control-full')
        const controlBtn = this.player.template.playStatBtn
        const templateWrap = this.player.template.container
        controlBtn.addEventListener(
            'click',
            (e: PointerEvent) => {
                this.player.play()
                e.stopPropagation()
            }
        )
        
        // 点击播放器区域暂停
        controlWarp.addEventListener('click', () => {
            if (templateWrap.classList.contains('hide-controller')) {
                return
            }

            if (templateWrap.classList.contains('bplayer-playing')) {
                this.player.pause()
            }
        })
    }

    bindControlBar() {
        function toggleControl(el: Element) {
            el.addEventListener('mouseenter', () => {
                setTimeout(function () {
                    const dataShow = el.getAttribute('data-show')
                    if (!+dataShow) {
                        el.setAttribute('data-show', '1')
                    }
                }, 200)
            })
            el.addEventListener('mouseleave', () => {
                setTimeout(function () {
                    const dataShow = el.getAttribute('data-show')
                    if (+dataShow) {
                        el.setAttribute('data-show', '0')
                    }
                }, 200)
            })
        }

        const speedEl = this.player.template.speedControlWrap
        const qualityEl = this.player.template.qualityControlWrap
        const settingsEl = this.player.template.settingsControlWrap
        const volumeEl = this.player.template.volumeControlWrap

        toggleControl(speedEl)
        qualityEl && toggleControl(qualityEl)
        toggleControl(settingsEl)
        volumeEl && toggleControl(volumeEl)

        this.player.template.updateQualityUI()
        this.player.template.updateSpeedUI()

        this.player.template.controlBar.addEventListener('click', (event: any) => {
            const target = event.target

            if (
                Array.from(target.classList).indexOf('bplayer-option-item') !==
                -1
            ) {
                // speed control
                if (target.classList.contains('speed')) {
                    const speed = target.getAttribute('data-speed')
                    this.player.setSpeed(speed)
                    speedEl.setAttribute('data-show', '0')
                }

                // quality control
                if (target.classList.contains('quality')) {
                    const quality = target.getAttribute('data-quality')
                    const label = target.getAttribute('data-label')
                    const qualityInfo = {
                        quality,
                        label,
                    }
                    this.player.switchQuality(qualityInfo)
                    qualityEl.setAttribute('data-show', '0')
                }
            }

            // bind switcher event
            if (target.className === 'bplayer-switcher') {
                const parent = target.parentElement || target.parentNode
                const newValue = +parent.getAttribute('data-value') ? '0' : '1'
                const settingName = target.getAttribute('data-name')
                parent.setAttribute('data-value', newValue)

                switch (settingName) {
                    case 'protect':
                        this.player.setProtect(+newValue)
                        break

                    case 'loop':
                        this.player.setLoop(+newValue)
                        break

                    case 'subtitle':
                        this.player.toggleSubtitle(+newValue)
                        break

                    default:
                        break
                }
            }

            event.stopPropagation()
        })
    }

    show() {
        setTimeout(() => {
            this.player.template.container.classList.remove('hide-controller')
        }, 500)
        this.hideTimer && clearTimeout(this.hideTimer)
        this.hideTimer = setTimeout(() => {
            this.hide()
        }, this.hideDelay)
    }

    hide() {
        !this.isDragingBar() &&
            this.player.template.container.classList.add('hide-controller')
        this.hideTimer && clearTimeout(this.hideTimer)
        this.hideTimer = null
    }

    isDragingBar() {
        return this.isDragingProgressBar || this.isDragingVolumeBar
    }

    initHideControllerTimer() {
        this.show()

        this.player.template.container.addEventListener('click', () => {
            this.show()
        })

        this.player.template.container.addEventListener('mouseenter', () => {
            this.show()
        })

        this.player.template.container.addEventListener('mousemove', () => {
            this.show()
        })
    }
}
