<video
    crossorigin="anonymous"
    class="bplayer-video"
    webkit-playsinline
    playsinline 
    x5-video-player-type="h5-page"
    x5-video-orientation="h5"
    x5-video-player-fullscreen="true"
    {{ if airplay }} x-webkit-airplay="allow" {{ /if }}
    {{ if poster }}poster="{{ poster }}"{{ /if }}
    {{ if preload }}preload="{{ preload }}"{{ /if }}
    {{ if url }}src="{{ url }}"{{ /if }}
    {{ if autoplay }}autoplay="true"{{ /if }}
    >
    {{ if subtitle.url }}
    <track kind="metadata" default src="{{ subtitle.url }}"></track>
    {{ /if }}
</video>