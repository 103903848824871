function secondToTime (second: any) {
   second = second || 0;
   if (second === 0 || second === Infinity || second.toString() === 'NaN') {
       return '00:00';
   }
   const add0 = (num: number) => (num < 10 ? '0' + num : '' + num);
   const hour = Math.floor(second / 3600);
   const min = Math.floor((second - hour * 3600) / 60);
   const sec = Math.floor(second - hour * 3600 - min * 60);

   return (hour > 0 ? [hour, min, sec] : [min, sec]).map(add0).join(':');
}

function random(start: number, end: number): number {
    return Math.floor(start + Math.random() * (end - start))
}

function debounce(func: Function, wait: number) {
    if(typeof func !== 'function') {
        throw new TypeError(`argument 'func' need a function type`)
    }
    let timeid: any
    return function() {
        let context = this,
            args = arguments
        timeid && clearTimeout(timeid)
        timeid = setTimeout(function() {
            func.apply(context, args)
        }, wait)
    }
}

// 将hex颜色转成rgb
function hexToRgba(hex:string, opacity:number) {
    let RGBA =
        'rgba(' +
        parseInt('0x' + hex.slice(1, 3)) +
        ',' +
        parseInt('0x' + hex.slice(3, 5)) +
        ',' +
        parseInt('0x' + hex.slice(5, 7)) +
        ',' +
        opacity +
        ')'
    return {
        red: parseInt('0x' + hex.slice(1, 3)),
        green: parseInt('0x' + hex.slice(3, 5)),
        blue: parseInt('0x' + hex.slice(5, 7)),
        rgba: RGBA
    }
}

function throttle() {

}

const utils = {
    secondToTime,
    random,
    debounce,
    throttle,
    hexToRgba
}

export default utils