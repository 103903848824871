/**
 * Events
 */

import BPlayer from '..'
import Icons from '../function/icons'

export const eventTypes = {
    PLAY: 'play',
    PLAYING: 'playing',
    PAUSE: 'pause',
    SEEKING: 'seeking',
    WAITING: 'waiting',
    SEEKED: 'seeked',
    ENDED: 'ended',
    VOLUME_CHANGE: 'volume_change',
    RATE_CHANGE: 'rate_change',
    NORMAL: 'normal',
    SUBTITLE_HIDE: 'subtitle_hide',
    SUBTITLE_SHOW: 'subtitle_show',
    SUBTITLE_CHANGE: 'subtitle_change',
    ERROR: 'error',
    CANPLAY: 'canplay',
    TIMEUPDATE: 'timeupdate',
    LOADEDMETADATA: 'loadedmetadata',
    QUALITY_CHANGE: 'quality_change',
    ENDED_AD: 'ended_ad',
    FULLSCREEN_CHANGE: 'full_screen_change'
}

export default class Events {
    public callbacks: any

    public player: BPlayer

    static events: Array<string> = [
        eventTypes.PAUSE,
        eventTypes.ENDED,
        eventTypes.PLAY,
        eventTypes.WAITING,
        eventTypes.PLAYING,
        eventTypes.SEEKED,
        eventTypes.SEEKING,
        eventTypes.RATE_CHANGE,
        eventTypes.VOLUME_CHANGE,
        eventTypes.NORMAL,
        eventTypes.SUBTITLE_SHOW,
        eventTypes.SUBTITLE_CHANGE,
        eventTypes.SUBTITLE_HIDE,
        eventTypes.ERROR,
        eventTypes.CANPLAY,
        eventTypes.TIMEUPDATE,
        eventTypes.LOADEDMETADATA,
        eventTypes.QUALITY_CHANGE,
        eventTypes.ENDED_AD,
        eventTypes.FULLSCREEN_CHANGE
    ]

    constructor(player: BPlayer) {
        this.callbacks = {}
        this.player = player
        this.bindCustomEvent()
    }

    bindCustomEvent() {
        document.addEventListener("visibilitychange", () => {
            if (this.player.video.paused) {
                this.player.template.playBtn.innerHTML = Icons.play
                this.player.template.container.classList.remove('bplayer-playing')
            } else {
                this.player.template.playBtn.innerHTML = Icons.stop
                this.player.template.container.classList.add('bplayer-playing')
            }
        });
    }

    on(name: string | Array<string>, callback: Function) {
        const that = this
        function singleListen(name: string) {
            if (
                Events.events.indexOf(name) !== -1 &&
                typeof callback === 'function'
            ) {
                if (that.callbacks[name]?.length) {
                    that.callbacks[name].push(callback)
                } else {
                    that.callbacks[name] = [callback]
                }
            } else {
                throw new Error(`${name} is not a event.`)
            }
        }
        if (typeof name === 'string') {
            singleListen(name)
        } else if (name.constructor.name === 'Array') {
            name.forEach(item => {
                singleListen(item)
            })
        }
        return that
    }

    trigger(name: string, data?: any) {
        if (this.validEvent(name)) {
            if (this.player?.debugger && name !== eventTypes.TIMEUPDATE) {
                const value = (typeof data !== 'object' ? data : '') || ''
                this.player.logger.info('triggered: ' + name + ' ' + value)
                this.player.debugger.addEvent(name, data)
            }
            
            let callbackList = this.callbacks[name]

            if (callbackList?.length) {
                callbackList.forEach((callback: Function) => {
                    callback(data, name)
                })
            }
        } else {
            throw new Error(`there is without any callbacks for ${name}`)
        }
        return this
    }

    off(name: string) {
        if (name === '*') {
            this.callbacks = {}
        }
        if (this.validEvent(name)) {
            this.callbacks[name] = []
        }
    }

    validEvent(name: string) {
        return Events.events.indexOf(name) !== -1
    }
}