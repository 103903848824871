define([], function () {
    'use strict';
    define(function (require) {
        function s4() {
            return ((1 + Math.random()) * 65536 | 0).toString(16).substring(1);
        }
        function guid() {
            return [
                s4() + s4(),
                s4(),
                s4(),
                s4(),
                s4() + s4() + s4()
            ].join('-');
        }
        return function getUuid() {
            return guid();
        };
    });
});