import play from '../assets/icons/play.svg'
import stop from '../assets/icons/stop.svg'
import fullscreenOn from '../assets/icons/fullscreen-on.svg'
import fullscreenOff from '../assets/icons/fullscreen-off.svg'
import setting from '../assets/icons/setting.svg'
import speakerOn from '../assets/icons/speaker-on.svg'
import speakerOff from '../assets/icons/speaker-off.svg'
import barrageOn from '../assets/icons/barrage-on.svg'
import barrageOff from '../assets/icons/barrage-off.svg'
import loading from '../assets/icons/loading.svg'

export default {
    play,
    stop,
    fullscreenOff,
    fullscreenOn,
    setting,
    speakerOn,
    barrageOff,
    barrageOn,
    loading,
    speakerOff
}