import BPlayer from './Player'
import { KEY_SEEK_OFFSET, KEY_VOLUME_OFFSET, SECRET_CODE } from '../types/constant'

class Hotkey {
    private player: BPlayer

    public secretCode: string = ''

    constructor(player: BPlayer) {
        this.player = player
        this.initHotkey()
    }

    initHotkey() {
        this.player.container.setAttribute('tabindex', '0')
        this.player.container.addEventListener('keydown', (event) => {
            event.preventDefault();
            let volume = this.player.video.volume
            let currentTime = this.player.video.currentTime

            this.secretCode += event?.key || ''

            switch (event.keyCode) {
                case 38:
                    this.player.setVolume(volume + KEY_VOLUME_OFFSET)
                    break;
                case 40:
                    this.player.setVolume(volume - KEY_VOLUME_OFFSET)
                    break;
                case 37:
                    if (this.player.options.enableDrag) {
                        this.player.seek(currentTime - KEY_SEEK_OFFSET)
                    }
                    break;
                case 39:
                    if (this.player.options.enableDrag) {
                        this.player.seek(currentTime + KEY_SEEK_OFFSET)
                    }
                    break;
                case 32:
                    this.player.toggle()
                    break;

                // ESC：取消全屏
                default: 
                    break;
            }

            if (this.secretCode.slice(-SECRET_CODE.length) === SECRET_CODE) {
                this.player.toggleDebugPanel()
                this.secretCode  = ''
            }
        })
    }

}

export default Hotkey;