import BPlayer from '..'
import { eventTypes } from './Events'
const getUuid = require('../utils/getUuid')
const getBrowser = require('../utils/getBrowser')
const log = require('../utils/log')

const domainSuffix = 'baijiayun.com'

const DEFAULT_INTERVAL = 120

const REPORT_EVENTS_ACCORDING_TO_PLAYER_EVENT: any = {
    play: 'play',
    playing: 'play',
    pause: 'pause',
    waiting: 'block',
    seeking: 'seek',
    seeked: 'seek',
    ratechange: 'speedup',
    error: 'playerror',
    normal: 'normal',
    ended: 'endplay',
    changecdn: 'changecdn',
    resolution: 'resolution',
    firstplaywait: 'firstplaywait',
}

export default class Reporter {

    public interval: number = 120

    public api: string

    // 最后一次上报的时间
    public lastReportTime: number

    public player: BPlayer

    public reportOptions: any = {
        firstPlay: true,
        canBlock: false,
        waitTime: 0,
        loadStartTime: 0,
        blockStartTime: 0,
        isBlocked: false,
        isSeeking: false,
        isResolutionChanged: false,
        seekfrom: 0 
    }

    public reportInterval: any = null

    constructor(player: BPlayer) {
        this.player = player

        this.interval =
            this.player.options.reporter?.interval || DEFAULT_INTERVAL

        this.init()
    }

    normalReportInterval() {
        const that = this
        if (!that.reportInterval) {
            that.reportInterval = setInterval(function () {
                that.report('normal')
            }, that.interval * 1000)
        }
    }

    init() {
        let lastCurrentTime = 0
        this.player
            .on(eventTypes.PLAY, () => {
                if (!this.player.useFFPlayer) {
                    this.report('play')
                }
            })
            .on(eventTypes.PLAYING, () => {
                if (this.player.useFFPlayer) {
                    this.report('play')
                }
                this.reportOptions.isResolutionChanged = false;
                if (this.reportOptions.canBlock && this.reportOptions.isBlocked) {
                    // 正式播放上报卡顿结束
                    this.reportOptions.isBlocked = false;
                    this.reportOptions.waitTime = (new Date()).getTime() - this.reportOptions.blockStartTime;
                    this.report('blockend')
                    this.report('playing')
                }
                this.normalReportInterval()
            })
            .on(eventTypes.SEEKING, () => {
                if (!this.reportOptions.isSeeking) {
                    this.reportOptions.isSeeking = true;
                }
            })
            .on(eventTypes.ENDED, () => {
                this.report('ended')
            })
            .on(eventTypes.SEEKED, () => {
                if (this.reportOptions.firstPlay) {
                    return;
                }
                this.reportOptions.isSeeking = false;
                if (!this.reportOptions.isResolutionChanged) {
                    // 清晰度切换的seek不上报
                    this.report('seeked');
                }
                this.reportOptions.seekfrom = this.player.video.currentTime;
            })
            .on(eventTypes.WAITING, () => {
                // 上报卡顿开始
                if (!this.reportOptions.firstPlay && this.reportOptions.canBlock && !this.reportOptions.isSeeking) {
                    this.reportOptions.isBlocked = true;
                    this.reportOptions.blockStartTime = (new Date()).getTime();
                    this.report('waiting');
                }
            })
            .on([eventTypes.ERROR, eventTypes.PAUSE, eventTypes.ENDED], (data: any, event: string) => {
                if (!this.reportOptions.isSeeking) {
                    // 非seek导致的进行上报且打断Normal
                    this.report(event);
                }
            })
            .on(eventTypes.RATE_CHANGE, () => {
                this.report('ratechange')
            })
            .on(eventTypes.CANPLAY, () => {
                if (this.reportOptions.firstPlay) {
                    this.reportOptions.firstPlay = false;
                    var nowTime = (new Date()).getTime();
                    this.reportOptions.waitTime = nowTime - this.reportOptions.loadStartTime;
                    this.report('firstplaywait')
                }
            })
            .on(eventTypes.TIMEUPDATE, () => {
                if (Math.abs(this.player.video.currentTime - lastCurrentTime) >= 3) {
                    this.reportOptions.seekfrom = lastCurrentTime;
                }
                lastCurrentTime = this.player.video.currentTime;
            })
            .on(eventTypes.QUALITY_CHANGE, () => {
                this.reportOptions.isResolutionChanged = true
            })
    }

    getParams(event: string, extInfo?: any) {
        const player = this.player
        const options = player.options
        const videoInfo = options.videoInfo

        let bufendtime,
            bufbegintime,
            buffered = player.video.buffered

        if (buffered && buffered.length) {
            bufbegintime = buffered.start(buffered.length - 1)
            bufendtime = buffered.end(buffered.length - 1)
        }

        if (this.player.useFFPlayer) {
            bufendtime = this.player.video.stats.preloadTime
        }

        let params: any = {
            fid: videoInfo?.video_info?.video_id,
            guid: videoInfo?.guid,
            type: 'video_vod',
            partner_id: videoInfo?.video_info?.partner_id,
            totaltime: Math.round(player.video.duration * 1000 || 0),
            playfiletype: options.video?.type,
            playbegintime: Math.round(this.lastReportTime || 0),
            playendtime: Math.round(player.currentTime * 1000 || 0),
            event: REPORT_EVENTS_ACCORDING_TO_PLAYER_EVENT[event] || 'normal',
            bufendtime: Math.round(bufendtime) || '',
            bufbegintime: Math.round(bufbegintime) || '',
            filesize: videoInfo.play_info[player.quality]?.size || 0,
            cdn: options.video?.cdn_info?.cdn,
            resolution: player.quality,
            speedup: player.video.playbackRate,
            userName: options.user?.name || '',
            userNumber: options.user?.number || '',
        }

        if (event == 'seeked') {
            // seek结束上报seek和seekto的时间点
            params.playendtime = Math.round(this.reportOptions.seekfrom * 1000)
            params.seekto = Math.round(player.video.currentTime * 1000)
            this.reportOptions.seekfrom = Math.round(player.video.currentTime)
        }

        // 正常播放的卡顿上报
        if (params.event == 'play') {
            params.playbegintime = params.playendtime
            this.reportOptions.canBlock = true
        } else if (params.event != 'normal') {
            // 非play 状态不上报block
            this.reportOptions.canBlock = false
        }

        // 卡顿结束和首次起播上报waittime
        if (
            this.reportOptions.waitTime &&
            (params.event == 'firstplaywait' || params.event == 'blockend')
        ) {
            params.waittime = Math.round(this.reportOptions.waitTime)
            this.reportOptions.waitTime = 0
        }

        params.duration = Math.round(params.playendtime - params.playbegintime)
        this.lastReportTime = Math.round(player.video.currentTime * 1000)

        return params
    }

    report(event: string, extInfo?: any) {
        // 暂停和停止播放清除计时器
        if (event == 'pause' || event == 'ended') {
            clearInterval(this.reportInterval)
            this.reportInterval = null
        }

        const data = this.getParams(event, extInfo)
        const uuid = getUuid()
        const customParams = this.player.options.reporter?.custom || {}

        const webData = {
            clientype: 5,
            net: 1,
            uuid: uuid,
            browser: getBrowser(),
            version: '1.3.1',
            env: this.player.options.env,
            customstr: '',
        }

        Object.assign(data, webData, customParams)

        let env = ''
        if (this.player.options.env == 'test') {
            env = 'test-'
        }

        log('//' + env + 'click.' + domainSuffix + '/gs.gif', data)

        // 上报点播阿里云日志
        data.APIVersion = '0.6.0'
        log(
            '//bjy-log.cn-beijing.log.aliyuncs.com/logstores/' +
                env +
                'frontend-video/track_ua.gif',
            data
        )
    }
}
