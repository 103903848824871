import BPlayer from '..';
import playerTpl from '../components/player.art';
import adTpl from '../components/advertisement.art';
import errorTpl from '../components/error.art';
import createFFPlayer from '../function/createFFPlayer';
import icons from '../function/icons';
import { PlayerOptions } from './Player';
import applyNewTheme from '../function/applyNewTheme'
import Utils from '../function/utils'
import os from 'bjy-common/lib/util/os';

// render video element
export function getVideoData(options: PlayerOptions) {
    return {
        poster: options.poster, 
        airplay: options.airplay, 
        preload: options.preload, 
        url: options.video.url, 
        autoplay: options.autoplay, 
        subtitle: options.plugins.subtitle
    }
}

// render controller element
export function getControllerData(options: PlayerOptions) {
    let settings = [
        {
            label: '护眼模式',
            value: 0,
            name: 'protect'
        },
        {
            label: '循环播放',
            value: 0,
            name: 'loop'
        }
    ]
    if (options.plugins.subtitle?.url) {
        settings.push({
            label: '字幕',
            value: 1,
            name: 'subtitle'
        })
    }
    return {
        Icons: icons,
        playedTime: '00:00',
        totalTime: '00:00',
        title: options.title || '',
        rateList: options.rateList.map(speed => {
            return {
                value: speed,
                label: speed == 1 ? '倍速' : speed + 'x'
            }
        }),
        qualityList: options.video.quality || [],
        settings,
        enableBarrage: options.plugins.barrage,
        isH5: options.isH5,
        logo: options.logo
    }
}

// render ffplayer
export function renderFFPlayer(player: BPlayer) {
    const canvas: HTMLCanvasElement = player.container.querySelector('#bplayer-ffplayer')
    return createFFPlayer(canvas, player.options);
}

// render advertisement
export function renderAd(ad: any, container: HTMLElement) {
    const adHTML = adTpl(ad)

    container.insertAdjacentHTML('afterbegin', adHTML)

    return container.querySelector('.bplayer-ad-wrap')
}

// render error tip
export function showError(text: string, container: HTMLElement) {
    text = text || '无法播放'
    const errorHTML = errorTpl({text: text})
    container.innerHTML = errorHTML
}

// render all player
export function render(player: BPlayer) {
    const container = player.container

    const playerHTML = playerTpl({
        videoData: getVideoData(player.options),
        controllerData: getControllerData(player.options),
        useFFPlayer: player.useFFPlayer,
        subtitle: player.options.plugins.subtitle,
        isH5: player.isH5,
        startVideo: player.options.startVideo,
        endVideo: player.options.endVideo,
        Icons: icons,
        isLowEnd: os.android && (+os.version.split('.')[0] <= 9)
    })
    if (container) {
        container.innerHTML = playerHTML;
    } else {
        throw new Error('player container can not be null.')
    }
}

export default class Template {

    private options: PlayerOptions

    public player: BPlayer

    public container: HTMLElement

    public playBtn: HTMLElement

    public playStatBtn: HTMLElement

    public speedControlWrap: HTMLElement

    public qualityControlWrap: HTMLElement

    public settingsControlWrap: HTMLElement

    public volumeControlWrap: HTMLElement

    public video: any

    public progressLoaded: HTMLDivElement

    public progressPlayed: HTMLDivElement

    public showThumbnails: HTMLDivElement

    public progressBar: HTMLDivElement

    public controlBar: HTMLDivElement

    public volumeBar: HTMLDivElement

    public volumeBarActive: HTMLDivElement

    public playerStatIcon: HTMLDivElement

    public fullScreenBtn: HTMLDivElement

    public protectionMask: HTMLDivElement
    
    public marqueeWrap: HTMLDivElement

    public subtitleFakerVideo: HTMLVideoElement

    public subtitleWrap: HTMLElement
    
    public customWrap: HTMLDivElement

    public waterMark: HTMLDivElement

    public infoWrap: HTMLDivElement

    public actionTip: HTMLDivElement

    public debugPanel: HTMLDivElement

    public volumeControlBtn: HTMLSpanElement

    public barrageControlBtn: HTMLSpanElement

    public startEndVideoWrap: HTMLDivElement

    public lowEndTipBar: HTMLDivElement

    constructor(player: BPlayer) {
        this.player = player
        this.options = player.options
        this.container = player.container

        render(this.player)

        this.init()

        if (player.useFFPlayer) {
            this.video = renderFFPlayer(player)
        }

        applyNewTheme(this.options.theme)

        // this.container.style.backgroundImage = `url('${this.options.background}')`
    }

    changeTheme(themeColor: string) {
        applyNewTheme(themeColor)
    }

    init() {
        this.container = this.container.querySelector('.bplayer-wrap')
        this.playBtn = this.container.querySelector('.bplayer-play-btn')
        this.playStatBtn = this.container.querySelector('.bplayer-control-full .icon-center')
        this.video = this.container.querySelector('.bplayer-video')
        this.subtitleFakerVideo = this.container.querySelector('#ffplayer-subtitle-faker')
        
        this.controlBar = this.container.querySelector('.bplayer-control-bar')
        this.progressPlayed = this.container.querySelector('.bplayer-progress-played')
        this.progressLoaded = this.container.querySelector('.bplayer-progress-loaded')
        this.showThumbnails = this.container.querySelector('.bplayer-show-thumbnails')
        this.progressBar = this.container.querySelector('.bplayer-progress-bar')
        this.volumeBar = this.container.querySelector('.bplayer-volume-bar')
        this.volumeBarActive = this.container.querySelector('.bplayer-volume-bar-active')


        this.speedControlWrap = this.container.querySelector('.bplayer-speed-control')
        this.qualityControlWrap = this.container.querySelector('.bplayer-quality-control')
        this.settingsControlWrap = this.container.querySelector('.bplayer-settings-control')
        this.volumeControlWrap = this.container.querySelector('.bplayer-volume-control')
        this.playerStatIcon = this.container.querySelector('.bplayer-stat-icon')
        
        this.customWrap = this.container.querySelector('.bplayer-custom-wrap')
        this.protectionMask = this.container.querySelector('.bplayer-eye-protection')
        this.marqueeWrap = this.container.querySelector('.bplayer-marquee-wrap')
        this.subtitleWrap = this.container.querySelector('.bplayer-subtitle')
        this.waterMark = this.container.querySelector('.bplayer-watermark-wrap')

        this.infoWrap = this.container.querySelector('.bplayer-info-wrap')
        this.actionTip = this.infoWrap.querySelector('.bplayer-action-tip')

        this.volumeControlBtn = this.volumeControlWrap?.querySelector('.icon')
        this.barrageControlBtn = this.container.querySelector('.bplayer-barrage-control .icon')
        this.fullScreenBtn = this.container.querySelector('.bplayer-full-screen-control .icon')

        this.debugPanel = this.container.querySelector('.bplayer-debug-wrap')

        this.startEndVideoWrap = this.container.querySelector('.bplayer-start-end-video-wrap')
        this.lowEndTipBar = this.container.querySelector('.bplayer-low-end-tip')
    }

    // loaded bar
    updateLoadedBar(time: number) {
        const width = time / this.player.duration * this.progressBar.offsetWidth
        this.progressLoaded.style.width = width + 'px'
    }

    // 更新进度条
    updateProgressBar(e?: PointerEvent | TouchEvent, time?: number) {
        let barPosition =
                this.progressBar.getBoundingClientRect(),
            barWidth = barPosition.width,
            playedWidth = 0

        if (!e) {
            playedWidth =
                (barWidth * (time || this.player.video.currentTime)) /
                (this.player.video.duration || this.player.options.videoInfo.duration)
        } else {
            // @ts-ignore
            let mouseX = e.clientX || e.changedTouches[0].clientX,
                offsetX = mouseX - (barPosition.x || barPosition.left)

            playedWidth = Math.min(offsetX, barWidth)
            playedWidth = Math.max(playedWidth, 0)
        }

        this.progressPlayed.style.width = playedWidth + 'px'
        // 时间的更新也放进来
        this.controlBar.querySelector(
            '.played-time'
        ).innerHTML = Utils.secondToTime(time || this.player.video.currentTime)

        return playedWidth / barWidth
    }

    // 更新音量条
    updateVolumeBar(e?: PointerEvent) {
        if (this.player.isH5) {
            return
        }
        let barPosition =
                this.volumeBar.getBoundingClientRect(),
            barHeight = barPosition.height,
            activeHeight = 0

        if (!e) {
            activeHeight = barHeight * this.player.video.volume
        } else {
            let mouseY = e.clientY,
                offsetY = barHeight - (mouseY - (barPosition.y || barPosition.top))

            activeHeight = Math.min(offsetY, barHeight)
            activeHeight = Math.max(activeHeight, 0)
        }

        if (activeHeight > 0) {
            this.volumeControlBtn.innerHTML = icons.speakerOn
        } else {
            this.volumeControlBtn.innerHTML = icons.speakerOff
        }

        this.volumeBarActive.style.height = activeHeight + 'px'
        this.controlBar.querySelector(
            '.bplayer-volume-control .volume-value'
        ).innerHTML = '' + parseInt('' + (activeHeight / barHeight) * 100)
        return activeHeight / barHeight
    }

    // 更新播放速度UI
    updateSpeedUI() {
        const speedEl = this.speedControlWrap
        // TODO: 限制1080p视频最高限制1.5倍速
        speedEl.setAttribute('data-value', '' + (this.player.speed || 1))
        speedEl.querySelector('.bplayer-current-speed').innerHTML =
            this.setActiveOptionItem(speedEl, 'speed')
    }

    // 更新清晰度UI
    updateQualityUI() {
        const qualityEl = this.player.template.qualityControlWrap
        if (qualityEl) {
            qualityEl.setAttribute('data-value', '' + this.player.quality)
            qualityEl.querySelector('.bplayer-current-quality').innerHTML =
                this.setActiveOptionItem(qualityEl, 'quality')
        }
    }

    // 设置options list 下拉列表UI
    setActiveOptionItem(el: Element, attr: string) {
        const value = el.getAttribute('data-value')
        const childList = el.querySelectorAll('.bplayer-option-item')
        let label = ''

        Array.from(childList).forEach((child: HTMLElement) => {
            const childValue = child.getAttribute('data-' + attr)
            if (childValue === value) {
                child.classList.add('active')
                label = child.getAttribute('data-label')
            } else {
                child.classList.remove('active')
            }
        })

        return label
    }

}
