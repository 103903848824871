import BPlayer from '..'
import { EVENTS } from '../core/Player'

export default class Debugger {

    public player: BPlayer

    public wrapper: HTMLDivElement

    public infoPanel: HTMLDivElement

    public eventPanel: HTMLDivElement

    public stats: any = {}

    // 静态信息
    public staticStats: any = {}

    public statInterval: any = null

    public on: boolean = false

    constructor(player: BPlayer) {
        this.player = player
        this.wrapper = player.template.debugPanel
        this.infoPanel = this.wrapper.querySelector('.bplayer-debug-info')
        this.eventPanel = this.wrapper.querySelector('.bplayer-debug-event')
        this.init()
    }

    init() {
        if (this.player.useFFPlayer) {
            this.player.video.on(FFPlayer.Events.Player.STATS, (stats: any) => {
                this.stats = stats
                this.refreshInfo()
            })
        } else {
            this.player.on(EVENTS.PLAYING, () => {
                if (!this.statInterval) {
                    this.statInterval = setInterval(() => {
                        this.getHtml5VideoStat()
                    }, 1000)
                }
            })
            .on([EVENTS.PAUSE, EVENTS.ENDED], () => {
                clearInterval(this.statInterval)
                this.statInterval = null
            })
        }

        this.staticStats = {
            format: this.player.options.video.type
        }
    }

    getHtml5VideoStat() {
        if (this.on) {
            const video: HTMLVideoElement = this.player.video
            let stats: any = {}
            stats.videoWidth = video.videoWidth
            stats.videoHeight = video.videoHeight
            stats.totalVideoFrames = video.getVideoPlaybackQuality().totalVideoFrames
            stats.droppedVideoFrames = video.getVideoPlaybackQuality().droppedVideoFrames
            stats.duration = video.duration
            stats.currentSrc = video.currentSrc
            this.stats = stats
            this.refreshInfo()
        }
    }             

    refreshInfo() {
        if (this.on) {
            let listHtml = ''
            let stats = {...this.staticStats, ...this.stats}
            for(let key in stats) {
                listHtml += `<li class="item-list">${key}: ${this.stats[key]}</li>`
            }
            this.infoPanel.innerHTML = listHtml
        }
    }

    addEvent(name: string, data?: any) {
        let eventHtml: HTMLLIElement = document.createElement('li')
        eventHtml.innerHTML = `triggered: ${name}, ${data}`
        this.eventPanel.appendChild(eventHtml)
    }

    show() {
        this.on = true
        this.wrapper.classList.add('show')
    }

    hide() {
        this.on = false
        this.wrapper.classList.remove('show')
    }

    toggle() {
        if (this.wrapper.classList.contains('show')) {
            this.hide()
        } else {
            this.show()
        }
    }
}