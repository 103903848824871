import os from 'bjy-common/lib/util/os'
import { PlayerOptions } from '../core/Player'
import Storage from '../core/Storage'
import { ERROR_CDEO } from '../types/constant'
import { useWorker } from './createFFPlayer'
import getSupportFormats from './getSupportFormats'
import getVideoInfo from './getVideoInfo'
const decodeUrl = require('../utils/decodeUrl')

const watermarkPositionMap: any = {
    1: 'top-left',
    2: 'top-right',
    3: 'bottom-right',
    4: 'bottom-left',
}

export const videoTypeList = ['mp4', 'm3u8', 'ev1', 'ev2', 'flv']

export function getDefaultOptions() {
    return {
        enableDrag: true,
        container: document.getElementsByClassName('bplayer')[0],
        autoplay: false,
        theme: '#b7daff',
        logo: {},
        poster: '',
        background: '',
        loop: false,
        lang: navigator.language.toLowerCase(),
        airplay: true,
        preload: 'metadata',
        volume: 0.7,
        rateList: [0.5, 0.75, 1, 1.25, 1.5, 2],
        video: {},
        mutex: true,
        plugins: {
            subtitle: {
                color: '#fff',
                fontSize: 18,
                bottom: 64
            },
            marquee: {
                rollDuration: 10,
                blinkDuration: 10,
                type: 1,
                displayMode: 'roll',
                count: 2,
                fontOpacity: 1,
                backgroundOpacity: 1,
                duration: 30,
                interval: 0,
                position: 'top',
                fontSize: 14,
                color: '#000',
                value: '测试跑马灯',
            },
            waterMark: {
                width: 120,
                position: 'top right',
                url: 'https://live-cdn.baijiayun.com/homepage/asset/frontend/common/img/logo-blue_3dd169c315.png'
            }
        },
    }
}

export function autoFixType(url: string) {
    return url.split('.').pop().toLowerCase()
}

export function mergePluginOptions(plugins: any) {
    let defaultOptions: any = getDefaultOptions()
    if (typeof plugins !== 'object') {
        return {subtitle: {url: ''}}
    } else {
        for(let key in plugins) {
            let plugin = plugins[key]
            if (typeof plugin !== 'object') {
                plugin = {}
            } else {
                for(let subkey in plugin) {
                    if (!plugin[subkey]) {
                        delete plugin[subkey]
                    }
                }
            }
            plugins[key] = Object.assign(defaultOptions.plugins[key], plugins[key])
        }
    }
    if (!plugins.subtitle) { plugins.subtitle = {url: ''}}
    return plugins
}

export function formatAdOptions(ad: any) {
    return {
        link: ad.click_jump_url,
        description: ad.description,
        duration: ad.duration,
        bgsrc: ad.img_url,
        skip: ad.skip_ad,
        skipSeconds: ad.skip_ad_seconds,
        showTimer: ad.show_timer
    }
}

export default async function (options: PlayerOptions, storage?: Storage) {
    // 对插件的配置去空值和无效值
    options.plugins = mergePluginOptions(options.plugins)

    options = Object.assign(getDefaultOptions(), options)

    let videoInfo: any = {}

    if (typeof options !== 'object') {
        throw new Error('options must be an object.')
    }

    if (!options.container) {
        throw new Error('player container can not be null.')
    }

    if (!options.video?.url && !(options.vid && options.token)) {
        throw new Error('no video info')
    }

    // fix video
    if (options.video?.url) {
        let type: string = options.video.type
        if (!type || type === 'auto') {
            type = autoFixType(options.video.url)
        }
        if (videoTypeList.indexOf(type) === -1) {
            throw new Error('unsupported video type.')
        }
        options.video.type = type
    }

    // render 的时候需要逆序一下
    if (options.rateList?.length) {
        options.rateList = options.rateList.sort(
            (a: number, b: number) => {
                return b - a
            }
        )
    }

    // 获取video info
    if (options.vid && options.token) {
        const res: any = await getVideoInfo(options)
        options.videoInfo = videoInfo = res.data

        if (res.code !== 0) {
            return Promise.reject({
                code: videoInfo.code,
                msg: videoInfo.msg
            })
        }

        if (!getSupportFormats().includes(videoInfo.format)) {
            return Promise.reject({
                code: ERROR_CDEO.unsupported
            })
        }

        if (videoInfo?.definition?.length) {
            options.video.quality = videoInfo.definition
        }

        if (videoInfo?.format) {
            options.video.type = videoInfo.format
        }

        if (videoInfo.vod_default_definition) {
            options.quality = videoInfo.vod_default_definition
            if (storage && storage.get('quality') && videoInfo.player_enable_memory) {
                options.quality = storage.get('quality')
            }
        }

        // 设置title
        if (videoInfo.video_info?.title) {
            options.title = videoInfo.video_info.title
        }
        // 设置poster
        if (videoInfo.init_pic) {
            options.poster = videoInfo.init_pic
        }
        // 设置logo
        if (videoInfo.logo_url) {
            options.logo.url = videoInfo.logo_url
            options.logo.link = videoInfo.home_url
        }
        // 设置主题色
        if (videoInfo.player_skin) {
            options.theme = videoInfo.player_skin
        }
        // 设置水印
        if (videoInfo.video_watermark && videoInfo.video_watermark.pos) {
            options.plugins.waterMark = {
                url: videoInfo.video_watermark.url,
                position: watermarkPositionMap[videoInfo.video_watermark.pos]
            }
        }
        // 设置背景图片
        if (videoInfo.pre_load_logo) {
            options.background = videoInfo.pre_load_logo
        }
        // 设置字幕，暂时取第一条字幕
        if (videoInfo.subtitles?.length && videoInfo.subtitles[0].url) {
            options.plugins.subtitle.url = videoInfo.subtitles[0].url
        }
        // 设置片头片尾
        if (videoInfo.start_video) {
            options.startVideo = videoInfo.start_video
        }
        if (videoInfo.end_video) {
            options.endVideo = videoInfo.end_video
        }
        // 设置广告
        if (videoInfo.ad?.start?.length || videoInfo.ad?.end?.length) {
            options.hasAd = true
        }
        // 设置支持拖拽进度条
        if (options.enableDrag === undefined) {
            options.enableDrag = videoInfo.player_enable_drag == 1
        }
        
        // 设置记忆播放
        if (options.memory  === undefined) {
            options.memory = videoInfo.player_enable_memory == 1
        }
        
        // 设置跑马灯
        if (videoInfo.live_hourse_lamp && videoInfo.live_hourse_lamp.type) {
            options.plugins.marquee = {
                rollDuration: videoInfo.live_hourse_lamp.display_roll_duration,
                blinkDuration: videoInfo.live_hourse_lamp.display_blink_duration,
                type: videoInfo.live_hourse_lamp.type,
                displayMode: videoInfo.live_hourse_lamp.display_mode,
                count: videoInfo.live_hourse_lamp.count,
                fontOpacity: videoInfo.live_hourse_lamp.opacity,
                backgroundOpacity: videoInfo.live_hourse_lamp.background_opacity,
                fontSize: videoInfo.live_hourse_lamp.font_size,
                color: videoInfo.live_hourse_lamp.color,
                value: videoInfo.live_hourse_lamp.value,
                backgroundColor: videoInfo.live_hourse_lamp.background_color,
            }
        }
    }

    // 不存在链接中的默认清晰度
    if (options?.video?.quality) {
        let quality = options.video.quality
        let qualityTypeList = quality.map((quality) => quality.type)

        if (quality.length) {
            if (!qualityTypeList.includes(options.quality)) {
                options.quality = quality[0].type
            }
        }
    }

    // 设置默认播放url
    if (videoInfo.play_info) {
        const formatInfo = videoInfo.play_info
        const defaultQuality = formatInfo[options.quality]

        for (let key in formatInfo) {
            const format = formatInfo[key]
            format.cdn_list.forEach((cdn: any) => {
                let url = decodeUrl(cdn.enc_url)
                url = url.replace(/^https?:/, location.protocol)
                cdn.url = url.split('?')[0]
            })
        }

        options.video.cdn_info = defaultQuality.cdn_list[0]

        options.video.url = defaultQuality.cdn_list[0].url
    }

    return Promise.resolve(options)
}
