/**
 * 根据终端类型判断支持的视频格式
 * pc: [ev2, ev1, flv, mp4]
 * h5: [ev2, ev1, m3u8, mp4]
 */

import browser from 'bjy-common/lib/util/browser'
import os from 'bjy-common/lib/util/os'

export default function () {
    let supportFFplayer = FFPlayer.isSupported()

    // ios uc、qq浏览器不支持ffplayer
    if ((browser.uc || browser.qq) && os.ios) {
        supportFFplayer = false
    }
    // 安卓版本低于7.0不支持
    if (os.android && (+os.version.split('.')[0] <= 7)) {
        supportFFplayer = false
    }
    // 安卓 chrome 版本低于72的也不支持
    if (os.android && browser.chrome && (+browser.version.split('.')[0] <= 72)) {
        supportFFplayer = false
    }

    // window qq浏览器
    if (os.windows && browser.qq) {
        supportFFplayer = false
    }

    const isH5 = os.android || os.ipad || os.ios || os.itouch

    let formats = ['mp4']

    if (supportFFplayer) {
        isH5 ? formats.unshift('ev2', 'ev1', 'm3u8') : formats.unshift('ev2', 'ev1', 'flv')
    } else {
        isH5 && formats.unshift('m3u8')
    }

    return formats
}