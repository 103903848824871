define([], function () {
    'use strict';
    define(function (require) {
        return function getBrowser() {
            var userAgent = navigator.userAgent.toLowerCase();
            var isOpera = userAgent.indexOf('opera') > -1;
            if (isOpera) {
                return 1;
            }
            if (userAgent.indexOf('firefox') > -1) {
                return 5;
            }
            if (userAgent.indexOf('chrome') > -1) {
                return 3;
            }
            if (userAgent.indexOf('safari') > -1) {
                return 6;
            }
            if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('msie') > -1 && !isOpera) {
                return 2;
            }
            if (/rv:(\S)+\) like gecko/.test(userAgent)) {
                return 2;
            } else {
                return 7;
            }
        };
    });
});