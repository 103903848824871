import Utils from '../function/utils'
import BPlayer from '..'
import { eventTypes } from '../core/Events'

const TOP_LIMIT = 40
const BOTTOM_LIMIT = 50
const ROLL_SPEED = 10
export interface MarqueeOptions {
    // 跑马灯个数
    count?: number,
    // 滚动或者闪烁
    displayMode?: string,
    // 固定值或者昵称
    type?: number,
    // 是否加粗
    fontBold?: number,
    // 字体透明度
    fontOpacity?: number,
    // 背景透明度
    backgroundOpacity?: number,
    // 滚动间隔时长
    rollDuration?: number,
    // 闪烁间隔时间
    blinkDuration?: number,
    // 出现位置 bottom | top | random
    position?: string,
    // 字段值
    value?: string
    // 字体大小
    fontSize?: string
    // 颜色
    color?: string
    // 背景颜色
    backgroundColor?: string
}

export class Marquee {
    private player: BPlayer

    public options: MarqueeOptions

    public containerWidth: number

    public containerHeight: number

    public running: boolean

    public moveInterval: any

    public marqueeItemOffset: any

    public marqueeWrap: HTMLDivElement

    public marqueeItemList: Array<any>

    public siteCache: Array<any>

    constructor(player: BPlayer) {
        this.player = player
        this.containerWidth = this.player.container.offsetWidth
        this.containerHeight = this.player.container.offsetHeight
        this.marqueeWrap = this.player.template.marqueeWrap
        this.options = Object.assign(this.defaultOptions(), this.player.options.plugins.marquee)
        this.running = false

        this.player.on(
            eventTypes.FULLSCREEN_CHANGE, (isFullScreen: boolean) => {
                if (!isFullScreen) {
                    this.containerWidth = this.player.container.offsetWidth
                    this.containerHeight = this.player.container.offsetHeight
                } else {
                    this.containerWidth = document.body.clientWidth
                    this.containerHeight = document.body.clientHeight
                }
            }
        )
    }

    create() {
        this.marqueeItemList = []
        for(let i = 0; i < this.options.count; i++) {
            let marqueeItem: any = null
            marqueeItem = document.createElement('div')
            marqueeItem.className = 'bplayer-marquee-item'
            marqueeItem.left = this.containerWidth
            if (this.options.displayMode == 'roll') {
                marqueeItem.style.transform = `translateX(${this.containerWidth + Utils.random(10, 15) * i}px)`
            }
            this.style(marqueeItem)
            this.marqueeWrap.append(marqueeItem)
            this.marqueeItemOffset = {}
            this.marqueeItemOffset.height = marqueeItem.offsetHeight
            this.marqueeItemOffset.width = marqueeItem.offsetWidth
            this.marqueeItemList.push(marqueeItem)
        }
    }

    style(marqueeItem: any): void {
        if (this.options.fontSize) {
            this.options.fontSize = ('' + this.options.fontSize).replace('px', '') + 'px'
        }
        marqueeItem.style.padding = '10px'
        marqueeItem.style.fontSize = this.options.fontSize
        marqueeItem.style.color = Utils.hexToRgba(this.options.color, this.options.fontOpacity).rgba
        marqueeItem.style.backgroundColor = Utils.hexToRgba(this.options.backgroundColor, this.options.backgroundOpacity).rgba
        marqueeItem.style.fontWeight = this.options.fontBold == 1 ? 'bold' : 'normal'
        // 用户昵称user.usernumber
        if (this.options.type == 2) {
            if (this.player.options.user?.number || this.player.options.user?.name) {
                marqueeItem.innerHTML = this.player.options.user?.name || this.player.options.user?.number.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
            } else {
                marqueeItem.innerHTML = ''
            }
        } else {
            marqueeItem.innerHTML = this.options.value
        }
    }

    createSites() {
        this.siteCache = []
        const bottom = this.containerHeight - BOTTOM_LIMIT - this.marqueeItemOffset.height
        const right = this.containerWidth - this.marqueeItemOffset.width
        for (let i = 0; i < this.options.count; i++) {
            this.siteCache.push(this.getRandomSite(bottom, right))
        }
    }

    getRandomSite(bottom:number, right:number):any {
        let site = {
            top: Utils.random(TOP_LIMIT, bottom),
            left: Utils.random(0, right)
        }
        if (this.isRepeat(site)) {
            return this.getRandomSite(bottom, right)
        }
        return site
    }

    isRepeat(site:any) {
        return this.siteCache.some(item => {
            let axisRepeat = true
            if (this.options.displayMode == 'blink') {
                axisRepeat = Math.abs(site.left - item.left) <= this.marqueeItemOffset.width
            }
            return Math.abs(site.top - item.top) <= this.marqueeItemOffset.height && axisRepeat
        })
    }

    pause() {
        this.running = false
        clearInterval(this.moveInterval)
    }

    destroy() {
        if (this.moveInterval) {
            clearInterval(this.moveInterval)
            this.moveInterval = null
        }
        if (this.marqueeItemList && this.marqueeItemList.length > 0) {
            this.marqueeItemList.forEach(item => {
                item.remove()
                item = null
            })
            this.marqueeItemList = []
        }
    }

    

    defaultOptions() {
        return this.options || {
            rollDuration: 10,
            blinkDuration: 10,
            type: 1,
            displayMode: 'roll',
            count: 2,
            fontOpacity: 1,
            backgroundOpacity: 1,
            interval: 10,
            duration: 10,
            position: 'random',
            value: '这是一条跑马灯',
            fontSize: '20px',
            color: '#ffffff',
            backgroundColor: '#1795ff'
        }
    }
}

export class rollLamp extends Marquee {

    constructor(player: BPlayer) {
        super(player)
        this.init()
    }
    init() {
        super.destroy()
        super.create()
        this.run()
    }

    run() {
        this.running = true

        if(this.options.position == 'top') {
            this.marqueeItemList.forEach(item => {
                item.style.top = TOP_LIMIT + 'px'
                item.style.setProperty('bottom', 'initial')
            })
        }
        if(this.options.position == 'bottom') {
            this.marqueeItemList.forEach(item => {
                item.style.bottom = BOTTOM_LIMIT + 'px'
                item.style.setProperty('top', 'initial')
            })
        }
        if(this.options.position == 'random') {
            this.createSites()
            this.marqueeItemList.forEach((item, index) => {
                item.style.top = this.siteCache[index].top + 'px'
                item.style.setProperty('bottom', 'initial')
            })
        }

        let step = (this.containerWidth + this.marqueeItemOffset.width) / (ROLL_SPEED * 1000 / 17)

        this.moveInterval = setInterval(() => {
            this.marqueeItemList.forEach((marqueeItem) => {
                this.move(marqueeItem, step, this.marqueeItemOffset.width)
            })
        }, 17)
    }

    move(el: any, step: number, width: number) {
        let initialLeft = el.left
        if(initialLeft <= -width) {
            el.left = this.containerWidth
            this.destroy()
            setTimeout(() => {
                this.init()
            }, this.options.rollDuration * 1000)
        } else {
            el.left = initialLeft - step
            el.style.transform = `translateX(${initialLeft - step}px)`
        }
    }
}

export class blinkLamp extends Marquee {

    constructor(player: BPlayer) {
        super(player)
        this.init()
    }

    init() {
        super.destroy()
        super.create()
        this.run()
    }

    run(): void {
        this.running = true
        this.moveInterval = setInterval(() => {
            this.createSites()
            this.marqueeItemList.forEach((marqueeItem, index) => {
                marqueeItem.style.right = 'auto'
                marqueeItem.style.left = this.siteCache[index].left + 'px'
                marqueeItem.style.top = this.siteCache[index].top + 'px'
            })
        }, this.options.blinkDuration * 1000)
    }
}

export default {blinkLamp, rollLamp, Marquee}