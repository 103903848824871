define(['@babel/runtime-corejs3/core-js-stable/instance/slice'], function (_slice) {
    'use strict';
    var _interopRequireDefault = require('@babel/runtime-corejs3/helpers/interopRequireDefault');
    _slice = _interopRequireDefault(_slice);
    define(function (require, exports, module) {
        var base64 = require('./base64');
        return function decodeUrl(url) {
            var prefix = 'bjcloudvod://';
            if ('' == url || url.indexOf(prefix) !== 0) {
                return;
            }
            url = (0, _slice.default)(url).call(url, prefix.length, url.length).replace(/-/g, '+').replace(/_/g, '/');
            var pad = url.length % 4;
            if (pad == 2) {
                url += '==';
            } else if (pad == 3) {
                url += '=';
            }
            url = base64.decode(url);
            var factor = url.charCodeAt(0);
            var c = factor % 8;
            url = (0, _slice.default)(url).call(url, 1, url.length);
            var result = [];
            for (var i = 0, char; char = url[i]; i++) {
                var step = i % 4 * c + i % 3 + 1;
                result.push(String.fromCharCode(char.charCodeAt(0) - step));
            }
            return result.join('');
        };
    });
});