<div class="bplayer-wrap{{ if isH5 }} h5{{ /if }}">
    {{ if isH5 && isLowEnd }}
    <div class="bplayer-low-end-tip">
        <p class="bplayer-text">您的设备型号、版本较低，会出现卡顿现象</p>
        <span class="bplayer-close-btn">&times;</span>
    </div>
    {{ /if }}

    <div class="bplayer-media-wrap">
        {{ if useFFPlayer }}
            <canvas id="bplayer-ffplayer"></canvas>
            {{  if subtitle.url  }}
            <video crossorigin="anonymous" id="ffplayer-subtitle-faker">
                <track kind="metadata" default src="{{  subtitle.url  }}"></track>
            </video>
            {{  /if  }}
        {{ else }}
            {{ include './video.art' videoData }}
        {{ /if }}
    </div>

    {{ if startVideo || endVideo }}
    <div class="bplayer-start-end-video-wrap">
        {{ if startVideo }}
        <video class="bplayer-start-video" src="{{ startVideo }}"></video>
        {{ /if }}
        {{ if endVideo }}
        <video class="bplayer-end-video" src="{{ endVideo }}"></video>
        {{ /if }}
        <div class="bplayer-video-stat">{{@ Icons.play }}</div>
    </div>
    {{ /if }}

    <div class="bplayer-feature-wrap">
        <!-- 控制层 -->
        {{ include './controller.art' controllerData }}
        
        <div class="bplayer-info-wrap">
            <div class="bplayer-action-tip hide"></div>
        </div>
        <div class="bplayer-debug-wrap">
            <div class="bplayer-debug-info"></div>
            <div class="bplayer-debug-event"></div>
        </div>
        <div class="bplayer-custom-wrap">
            <div class="bplayer-danmu-wrap"></div>
            <div class="bplayer-logo"></div>
            <div class="bplayer-subtitle"></div>
            <div class="bplayer-marquee-wrap"></div>
            <!-- ...others -->
        </div>
    </div>

    <div class="bplayer-eye-protection"></div>
</div>