// @ts-nocheck
import BPlayer from '..'
import icons from './icons'

const canUseFullScreenApi =
    document.fullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled

export function requestFullscreen(player: BPlayer) {

    const video = player.video
    const docEle = player.template.container

    player.template.container.classList.add('bplayer-fullscreen')

    // 全屏api优先级最高
    if (canUseFullScreenApi) {
        if (docEle.requestFullscreen) {
            return docEle.requestFullscreen()
        } else if (docEle.webkitRequestFullScreen) {
            return docEle.webkitRequestFullScreen()
        } else if (docEle.mozRequestFullScreen) {
            return docEle.mozRequestFullScreen()
        } else {
            return docEle.msRequestFullscreen()
        }
    }

    if (player.os.ios && video && !player.useFFPlayer) {
        return Promise.resolve(video.webkitEnterFullscreen())
    }

    return Promise.resolve()
}

export function exitFullscreen(player: BPlayer) {

    const video = player.video

    player.template.container.classList.remove('bplayer-fullscreen')

    if (document.exitFullscreen) {
        return document.exitFullscreen()
    } else if (document.msExitFullscreen) {
        return document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
        return document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
        return document.webkitExitFullscreen()
    }

    if (player.os.ios && video && !player.useFFPlayer) {
        return Promise.resolve(video.webkitExitFullscreen())
    }

    return Promise.resolve()
}

// check if is fullscreen now
export function isFullScreen(player: BPlayer) {
    const video = player.video
    if (canUseFullScreenApi) {
        return !!(
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement
        )
    } 
    
    if (player.useFFPlayer) {
        return player.template.container.classList.contains('bplayer-fullscreen')
    }
    else {
        // safari on ios use video
        return video.webkitDisplayingFullscreen
    }
}

// 同步全屏状态
export function syncFullscreenStat(player: BPlayer) {
    player.isFullScreen = isFullScreen(player)
    if (player.isFullScreen) {
        player.template.fullScreenBtn.innerHTML = icons.fullscreenOff
        player.template.fullScreenBtn.setAttribute('bplayer-tip', '退出全屏')
    } else {
        player.template.fullScreenBtn.innerHTML = icons.fullscreenOn
        player.template.fullScreenBtn.setAttribute('bplayer-tip', '进入全屏')
    }
}
