<div class="bplayer-control-wrap">

  <!-- title -->
  <div class="bplayer-title">{{ title }}</div>

  <div class="bplayer-control-bar">
    <div class="left">
      <span class="icon bplayer-play-btn">{{@ Icons.play }}</span>
      <span class="text duration">
            <span class="played-time">{{ playedTime }}</span>
            <span class="spliter">/</span>
            <span class="total-time">{{ totalTime }}</span>
        </span>
    </div>
    <div class="bplayer-progress-bar {{ if !isH5 }}absolute{{ /if }}">
        <div class="bplayer-progress-loaded"></div>
        <div class="bplayer-progress-played"></div>
        <div class="bplayer-show-thumbnails">
            <div class="cursor"></div>
            <div class="time"></div>
        </div>
    </div>
    <div class="right">
      <!-- quality -->
      {{ if qualityList.length }}
      <div class="text bplayer-control bplayer-quality-control" data-value="superHD" data-show="0">
        <span class="bplayer-current-quality">
          高清
        </span>
        <ul class="bplayer-option-list" data-quality="{{ qualityList[0].type }}">
            {{ each qualityList }}
            <li class="bplayer-option-item quality" data-label="{{ $value.name }}" data-quality="{{ $value.type }}">{{ $value.name }}</li>
            {{ /each }}
        </ul>
      </div>
      {{ /if }}

      <!-- speed -->
      <div class="text bplayer-control bplayer-speed-control" data-value="1" data-show="0">
        <span class="bplayer-current-speed">
          倍速
        </span>
        <ul class="bplayer-option-list" data-speed="1">
            {{ each rateList }}
            <li class="bplayer-option-item speed" data-label="{{ $value.label }}" data-speed="{{ $value.value }}">{{ $value.value }}x</li>
            {{ /each }}
        </ul>
      </div>

      <!-- barrage -->
      {{ if enableBarrage }}
      <div class="bplayer-control bplayer-barrage-control">
        <span bplayer-tip="弹幕开" class="icon">{{@ Icons.barrageOn }}</span>
      </div>
      {{ /if }}

      <!-- volume -->
      {{ if !isH5 }}
      <div class="bplayer-control bplayer-volume-control" data-show="0">
        <span class="icon">{{@ Icons.speakerOn }}</span>
        <div class="bplayer-option-list volume">
          <span class="volume-value">70</span>
          <div class="bplayer-volume-bar">
            <div class="bplayer-volume-bar-active"></div>
          </div>
        </div>
      </div>
      {{ /if }}

      <!-- settings -->
      <div class="bplayer-control bplayer-settings-control" data-show="0">
        <span class="icon">{{@ Icons.setting }}</span>
        <ul class="bplayer-option-list settings">
          {{ each settings }}
            <li class="bplayer-option-item space-between" data-name="{{ $value.name }}" data-value="{{ $value.value }}">
              <span>{{ $value.label }}</span>
              <span data-name="{{ $value.name }}" class="bplayer-switcher"></span>
            </li>
          {{ /each }}
        </ul>
      </div>

      <!-- fullscreen -->
      <div class="bplayer-control bplayer-full-screen-control">
        <span bplayer-tip="进入全屏" data-placement="right" class="icon">{{@ Icons.fullscreenOn }}</span>
      </div>

      {{ if !isH5 && logo.url }}
      <a class="bplayer-logo-link" href="{{ logo.link || 'javascript:;' }}" target="{{ logo.link ? '_blank' : '_self'}}">
        <img class="bplayer-logo" src="{{ logo.url }}">
      </a>
      {{ /if }}

    </div>
  </div>

  <div class="bplayer-control-full">
    <span class="icon-center bplayer-stat-icon">{{@ Icons.play }}</span>
  </div>
</div>
