export const INCREASE_VOLUME = 'increaseVolume'

export const REDUCE_VOLUME = 'reduceVolume'

export const FORWARD_SEEK = 'forwardSeek'

export const BACK_SEEK = 'backSeek'

// 音量步进值
export const KEY_VOLUME_OFFSET = 0.1

// 快进/快退步进值 s
export const KEY_SEEK_OFFSET = 10

// debug 暗号，键盘连续输入'debug'唤醒
export const SECRET_CODE = 'debug'

// error code map
export const ERROR_CDEO = {
    unsupported: -1 
}