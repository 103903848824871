import BPlayer from '..'
import Events, { eventTypes } from '../core/Events'

interface SubtitleOptions {
    fontSize: string | number
    bottom: string | number
    color: string
    backgroundColor: string
}

export default class Subtitle {
    public container: HTMLElement

    public video: any

    public options: SubtitleOptions

    public events: Events

    public on: boolean = false

    public player: BPlayer

    public hasInit: boolean = false

    private cueList: Array<VTTCue>

    private activeCue: VTTCue

    public backgroundColor: string

    public color: string

    constructor(player: BPlayer) {
        this.container = player.template.subtitleWrap
        this.video = player.video
        this.options = player.options.plugins.subtitle
        this.events = player.events
        this.player = player
    }

    init() {
        this.style()
        // 如果是ffplayer，需要用fake的video标签去加载字幕,通过监听timeupdate播放字幕
        if (this.player.useFFPlayer) {
            this.video = this.player.template.subtitleFakerVideo
            this.player.on(eventTypes.TIMEUPDATE, (time: number) => {
                // 初次存一下cues
                if (!this.cueList?.length) {
                    let textTracks = this.video.textTracks ? this.video.textTracks[0] : {}
                    if (textTracks.cues?.length) {
                        this.cueList = Array.from(textTracks.cues)
                    }
                } else {
                    this.setActiveCue(time)
                }
            })
        } else {
            if (this.video.textTracks && this.video.textTracks[0]) {
                const track = this.video.textTracks[0]
                track.oncuechange = () => {
                    const cue = track.activeCues[0]
                    this.updateCue(cue)
                }
            }
        }
        this.hasInit = true
    }

    // 根据current time查找更新对应的cue
    setActiveCue(time: number) {
        if (!this.on) return
        if (this.activeCue) {
            if (this.activeCue.startTime >= time && this.activeCue.endTime <= time) {
                return
            }
    
            if (this.activeCue.endTime < time) {
                this.activeCue = null
                this.updateCue()
                return
            }

            this.activeCue = this.cueList.find((cue: VTTCue) => {
                return cue.endTime >= time && cue.startTime <= time
            })

            this.updateCue(this.activeCue)
        } else {
            this.activeCue = this.cueList.find((cue: VTTCue) => {
                return cue.endTime >= time && cue.startTime <= time
            })
        }     
    }

    updateCue(cue?: VTTCue) {
        if (!this.on) return
        this.container.innerHTML = ''
        if (cue) {
            const template = document.createElement('div')
            template.appendChild(cue.getCueAsHTML())
            const trackHtml = template.innerHTML
                .split(/\r?\n/)
                .map((item) => `<p style="color: ${this.color};background-color: ${this.backgroundColor}">${item}</p>`)
                .join('')
            this.container.innerHTML = trackHtml
            this.events.trigger(eventTypes.SUBTITLE_CHANGE, cue)
        }
    }

    // 重新设置字幕样式
    style(style?: any) {
        let fontSize = style?.fontSize || this.options.fontSize.toString()
        let bottom = style?.bottom || this.options.bottom.toString()
        let color = style?.color || this.options.color
        this.container.style.fontSize = ('' + fontSize).replace('px', '') + 'px'
        this.container.style.bottom = ('' + bottom).replace('px', '') + 'px'
        let backgroundColor = style?.backgroundColor
        this.backgroundColor = backgroundColor
        this.color = color
        const subtitleLines = this.container.querySelectorAll('p')
        if (subtitleLines.length > 0) {
            for(let i=0; i<subtitleLines.length; i++) {
                // @ts-ignore
                subtitleLines[i].style.backgroundColor = backgroundColor
            }
        }
        this.container.style.color = color
    }

    show() {
        !this.hasInit && this.init()
        this.container.classList.remove('hide')
        this.events.trigger(eventTypes.SUBTITLE_SHOW)
        this.on = true
        this.player.showActionTip('字幕: 开')
    }

    hide() {
        this.container.classList.add('hide')
        this.events.trigger(eventTypes.SUBTITLE_HIDE)
        this.on = false
        this.player.showActionTip('字幕: 关')
    }

    toggle() {
        if (this.container.classList.contains('hide')) {
            this.show()
        } else {
            this.hide()
        }
    }
}
